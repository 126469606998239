import actions from '../actions/actions';

const initialState = {
  modals: {
    LoadScenario: true,
  },
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.HANDLE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modalSelect]: payload.value,
        },
      };
    default:
      return state;
  }
}
