const actions = {
  LOAD_AR: 'LOAD_AR',
  LOAD_AR_SUCCESS: 'LOAD_AR_SUCCESS',
  CREATE_AR_SUCCESS: 'CREATE_AR_SUCCESS',
  LOAD_ERROR: 'LOAD_ERROR',
  TOGGLE_MODAL_AR: 'TOGGLE_MODAL_AR',
  INPUT_AR: 'INPUT_AR',
  CREATE_AR: 'CREATE_AR',
  UPDATE_AR: 'UPDATE_AR',
  DELETE_AR: 'DELETE_AR',
  DELETE_AR_SUCCESS: 'DELETE_AR_SUCCESS',

  loadAR: reference => ({
    type: actions.LOAD_AR,
    payload: { reference },
  }),

  loadARSuccess: data => ({
    type: actions.LOAD_AR_SUCCESS,
    payload: { data },
  }),

  loadError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  toggleModalAR: (data = null) => ({
    type: actions.TOGGLE_MODAL_AR,
    payload: { data },
  }),

  inputARScene: data => ({
    type: actions.INPUT_AR,
    payload: { data },
  }),

  createARScene: (zipState, aRData, reference) => ({
    type: actions.CREATE_AR,
    payload: { zipState, aRData, reference },
  }),

  createARSceneSuccess: data => ({
    type: actions.CREATE_AR_SUCCESS,
    payload: data,
  }),

  updateARScene: (zipState, aRData, reference) => ({
    type: actions.UPDATE_AR,
    payload: { zipState, aRData, reference },
  }),

  deleteAR: (aRData, reference) => ({
    type: actions.DELETE_AR,
    payload: { aRData, reference },
  }),
  deleteARSucess: data => ({
    type: actions.DELETE_AR_SUCCESS,
    payload: data,
  }),
};

export default actions;
