import actions from '../actions/actions';

const initialState = {
  isLoading: false,
  errorMessages: false,
  videos: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.LOAD_VIDEOS:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.LOAD_VIDEOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        videos: payload.data,
        errorMessages: false,
      };
    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessages: payload.error,
      };
    case actions.TOGGLE_MODAL_VIDEO:
      return {
        ...state,
        modalActive: !state.modalActive,
        video: payload.data == null ? initialState.video : payload.data,
      };
    case actions.CREATE_VIDEO:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.CREATE_VIDEO_SUCCESS:
      console.log(payload);
      const name = payload.payload.videoState.name;
      const { questKey, scenarioId } = payload.payload.reference;
      const urlSource = `https://storage.googleapis.com/granaquestbackend.appspot.com/quests/${questKey}/${scenarioId}/videos/${name}`;
      return {
        ...state,
        isLoading: false,
        test: true,
        data: {
          ...state.data,
          [name]: {
            id: name,
            videoUrl: urlSource,
            source: name,
          },
        },
      };
    case actions.UPDATE_VIDEO:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.UPDATE_VIDEO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        video: payload.data,
        errorMessages: false,
      };
    case actions.DELETE_VIDEO:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.DELETE_VIDEO_SUCCESS:
      const newData = state.data;
      for (let video in newData) {
        if (video === payload.payload.videoData.id) {
          delete newData[video];
        }
      }
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
