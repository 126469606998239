import Scenario from "../../granaquest-library/scenarios/models/Scenario";
import NodeModel from "../models/NodeModel";

export interface LoadScenariosAction {
  type: typeof actions.LOAD_SCENARIOS;
  payload: {
    data: string;
  };
}

export interface LoadScenariosSuccessAction {
  type: typeof actions.LOAD_SCENARIOS_SUCCESS;
  payload: {
    data: { [key: string]: Scenario }
  };
}

export type ScenariosActionTypes =
  | LoadScenariosAction
  | LoadScenariosSuccessAction;

const actions = {
  LOAD_SCENARIOS: 'LOAD_SCENARIOS',
  LOAD_SCENARIOS_SUCCESS: 'LOAD_SCENARIOS_SUCCESS',
  LOAD_SCENARIO: "LOAD_SCENARIO",
  LOAD_SCENARIO_SUCCESS: "LOAD_SCENARIO_SUCCESS",
  RESET_SCENARIO: "RESET_SCENARIO",
  TOGGLE_MODAL_EDITOR: "TOGGLE_MODAL_EDITOR",
  TOGGLE_MODAL_ACTION: "TOGGLE_MODAL_ACTION",
  GET_URL_ICON: "GET_URL_ICON",
  INPUT_NODE: "INPUT_NODE",
  UPDATE_NODE: "UPDATE_NODE",
  UPDATE_NODE_SUCCESS: "UPDATE_NODE_SUCCESS",
  LOAD_SCENARIO_ERROR: "LOAD_SCENARIO_ERROR",
  ADD_NEW_NODE: "ADD_NEW_NODE",
  ADD_NEW_ARSCENE: "ADD_NEW_ARSCENE",
  ADD_NEW_END_CHAPTER: "ADD_NEW_END_CHAPTER",
  CHANGE_MODAL: "CHANGE_MODAL",
  CLOSE_ALL_MODAL: "CLOSE_ALL_MODAL",
  OPEN_CREATOR: "OPEN_CREATOR",
  OPEN_EDITOR: "OPEN_EDITOR",
  SCENARIO_ADD_DIALOG_ACTION: "SCENARIO_ADD_DIALOG_ACTION",
  SAVE_SCENARIO: "SAVE_SCENARIO",
  SAVE_SCENARIO_SUCCESS: "SAVE_SCENARIO_SUCCESS",
  EDIT_NODE: "EDIT_NODE",
  CLOSE_IS_SAVING: "CLOSE_IS_SAVING",
  SAVE_SCENARIO_DRAFT: "SAVE_SCENARIO_DRAFT",
  SAVE_SCENARIO_VALIDATOR: "SAVE_SCENARIO_VALIDATOR",
  UPDATE_SCENARIO_SUCCESS: "UPDATE_SCENARIO_SUCCESS",
  EDIT_TRIGGER: "EDIT_TRIGGER",
  ADD_MODIFY_UNLOCK_TRIGGER: "ADD_MODIFY_UNLOCK_TRIGGER",
  DELETE_UNLOCK_TRIGGER: "DELETE_UNLOCK_TRIGGER",
  MODAL_SELECTOR: "MODAL_SELECTOR",
  DELETE_DRAFT_SUCCESS: "DELETE_DRAFT_SUCCESS",
  DELETE_DRAFT: "DELETE_DRAFT",
  UPDATE_SCENARIO: "UPDATE_SCENARIO",
  DELETE_NODE: "DELETE_NODE",
  CREATE_NEW_NODES_AND_EDGES: "CREATE_NEW_NODES_AND_EDGES",
  ADD_PLAY_SOUND: "ADD_PLAY_SOUND",
  ACTIVE_GIVE_OBJECT: "ACTIVE_GIVE_OBJECT",
  CREATE_NFC: "CREATE_NFC",
  DELETE_NFC_ID: "DELETE_NFC_ID",
  CHANGE_GAME: "CHANGE_GAME",
  CHANGE_PICTURE_THEME: "CHANGE_PICTURE_THEME",
  CHANGE_PICTURE_THEME_SUCCESS: "CHANGE_PICTURE_THEME_SUCCESS",
  CREATE_NEW_SCENARIO: "CREATE_NEW_SCENARIO",
  CREATE_NEW_SCENARIO_SUCCESS: "CREATE_NEW_SCENARIO_SUCCESS",
  UPDATE_HINTS: "UPDATE_HINTS",
  SAVE_TOGGLE_OBJECT: "SAVE_TOGGLE_OBJECT",
  CHANGE_OBJECT: "CHANGE_OBJECT",
  CREATE_EDGE: "CREATE_EDGE",
  ADD_CHAPTER_ID: "ADD_CHAPTER_ID",

  createOneNode: (data: any) => ({
    type: actions.CREATE_EDGE,
    payload: data,
  }),

  changeObject: (data: any) => ({
    type: actions.CHANGE_OBJECT,
    payload: data,
  }),

  saveToggleObject: (data: any) => ({
    type: actions.SAVE_TOGGLE_OBJECT,
    payload: data,
  }),

  updateHints: ({ hints, hintId }: { hints: any; hintId: any; }) => ({
    type: actions.UPDATE_HINTS,
    payload: { hints: hints, hintId: hintId },
  }),

  createNewScenario: (questKey: string, scenario: Scenario, scenarioName: string) => ({
    type: actions.CREATE_NEW_SCENARIO,
    payload: {
      questKey: questKey,
      scenario: scenario,
      scenarioName: scenarioName,
    },
  }),
  createNewScenarioSuccess: () => ({
    type: actions.CREATE_NEW_SCENARIO_SUCCESS,
  }),

  changePictureGameSuccess: () => ({
    type: actions.CHANGE_PICTURE_THEME_SUCCESS,
  }),

  changePictureGame: (data: any) => ({
    type: actions.CHANGE_PICTURE_THEME,
    payload: data,
  }),

  changeGame: (data: any) => ({
    type: actions.CHANGE_GAME,
    payload: data,
  }),

  deleteNfcId: (nfcId: string) => ({
    type: actions.DELETE_NFC_ID,
    payload: nfcId,
  }),

  createNFC: (data: any) => ({
    type: actions.CREATE_NFC,
    payload: data,
  }),

  activeGiveObject: (data: any) => ({
    type: actions.ACTIVE_GIVE_OBJECT,
    payload: data,
  }),

  createNewNodesAndEdges: (data: any) => ({
    type: actions.CREATE_NEW_NODES_AND_EDGES,
    payload: data,
  }),

  editNode: (data: any) => ({
    type: actions.EDIT_NODE,
    payload: data,
  }),

  editTrigger: (data: any) => ({
    type: actions.EDIT_TRIGGER,
    payload: data,
  }),
  addPlaySound: (data: any) => ({
    type: actions.ADD_PLAY_SOUND,
    payload: data,
  }),

  addModifyUnlock: (data: any) => ({
    type: actions.ADD_MODIFY_UNLOCK_TRIGGER,
    payload: data,
  }),
  deleteUnlock: (data: any) => ({
    type: actions.DELETE_UNLOCK_TRIGGER,
    payload: data,
  }),
  openEditor: (data: any) => ({
    type: actions.OPEN_EDITOR,
    payload: data,
  }),

  saveScenario: (data: any) => ({
    type: actions.SAVE_SCENARIO,
    payload: data,
  }),

  updateProduction: (data: any) => ({
    type: actions.UPDATE_SCENARIO,
    payload: data,
  }),

  saveScenarioValidator: (data: any) => ({
    type: actions.SAVE_SCENARIO_VALIDATOR,
    payload: data,
  }),

  saveScenarioSuccess: (data: any) => ({
    type: actions.SAVE_SCENARIO_SUCCESS,
    payload: data,
  }),
  scenarioAddDialogAction: (data: any) => ({
    type: actions.SCENARIO_ADD_DIALOG_ACTION,
    payload: data,
  }),

  /**
   * Action to open/close the node creator window
   * true: allow select the type of node we want to create
   * false: close the node creation dialog
   */
  openNodeCreator: (data: any) => ({
    type: actions.OPEN_CREATOR,
    payload: data,
  }),

  modifyModal: (data: any) => ({
    type: actions.CHANGE_MODAL,
    payload: data,
  }),
  closeAllModal: () => ({
    type: actions.CLOSE_ALL_MODAL,
  }),

  nodeCreate: (data: any) => ({
    type: actions.ADD_NEW_NODE,
    payload: { data },
  }),

  createNodeArScene: (data: any) => ({
    type: actions.ADD_NEW_ARSCENE,
    payload: data,
  }),

  createNodeEndChapter: (data: any) => ({
    type: actions.ADD_NEW_END_CHAPTER,
    payload: data,
  }),

  addChapterId: (endGame: any, endingChapterId: any) => ({
    type: actions.ADD_CHAPTER_ID,
    payload: { nextChapter: endGame, chapterId: endingChapterId },
  }),

  loadScenarios: (questKey: string): LoadScenariosAction => ({
    type: actions.LOAD_SCENARIOS,
    payload: { data: questKey },
  }),

  loadScenariosSuccess: (data: { [key: string]: Scenario }): LoadScenariosSuccessAction => ({
    type: actions.LOAD_SCENARIOS_SUCCESS,
    payload: { data },
  }),

  loadScenario: (data: any) => ({
    type: actions.LOAD_SCENARIO,
    payload: { data },
  }),

  closeIsSaving: (data: any) => ({
    type: actions.CLOSE_IS_SAVING,
    payload: { data },
  }),
  modalSelector: (data: any) => ({
    type: actions.MODAL_SELECTOR,
    payload: data,
  }),

  loadScenarioSuccess: (data: any) => ({
    type: actions.LOAD_SCENARIO_SUCCESS,
    payload: { data },
  }),

  resetScenario: () => ({
    type: actions.RESET_SCENARIO,
  }),

  toggleModalEditor: (data: NodeModel | null = null) => ({
    type: actions.TOGGLE_MODAL_EDITOR,
    payload: { data },
  }),

  toggleModalAction: (data = null) => ({
    type: actions.TOGGLE_MODAL_ACTION,
    payload: { data },
  }),

  getUrlIcon: (data: any) => ({
    type: actions.GET_URL_ICON,
    payload: { data },
  }),

  inputNode: (data: any) => ({
    type: actions.INPUT_NODE,
    payload: { data },
  }),

  updateNode: (nodeData: any, reference: any) => ({
    type: actions.UPDATE_NODE,
    payload: { nodeData, reference },
  }),

  updateNodeSuccess: (data: any) => ({
    type: actions.UPDATE_NODE_SUCCESS,
    payload: { data },
  }),

  loadScenarioError: (error: any) => ({
    type: actions.LOAD_SCENARIO_ERROR,
    payload: { error },
  }),
  saveDraftScenario: (newScenario: any, reference: any, version: any) => ({
    type: actions.SAVE_SCENARIO_DRAFT,
    payload: { newScenario, reference, version },
  }),

  upddateScenarioSuccess: (data: any) => ({
    type: actions.UPDATE_SCENARIO_SUCCESS,
    payload: data,
  }),

  deleteDraft: (data: any) => ({
    type: actions.DELETE_DRAFT,
    payload: data,
  }),
  deleteDraftSuccess: (data: any) => ({
    type: actions.DELETE_DRAFT_SUCCESS,
    payload: data,
  }),
  deleteOneNode: (data: any) => ({
    type: actions.DELETE_NODE,
    payload: data,
  }),
};

export default actions;
