import { all, put, call, takeEvery } from 'redux-saga/effects';
import actions from '../actions/actions';
import { rsf, db } from '@iso/lib/firebase/firebase';
import { convertCollectionsSnapshotToMap } from '@iso/lib/firebase/firebase.util';

function* loadStats(questId) {
  const questKey = questId.payload.data;
  try {
    const documentRef = db
      .collection('quests')
      .doc(questKey)
      .collection('stats')
      .doc(questKey);
    const snapshots = yield call(rsf.firestore.getDocument, documentRef);
    yield put(actions.loadStatsSuccess(snapshots.data()));
  } catch (error) {
    yield put(actions.loadStatsError(error));
  }
}

function* loadGames(questId) {
  const { data } = questId.payload;
  try {
    const userRef = db
      .collection('users')
      .where('profiles', 'array-contains-any', ['developer', 'creator']);
    const profileSnapshot = yield call(rsf.firestore.getDocument, userRef);
    const profiles = yield call(
      convertCollectionsSnapshotToMap,
      profileSnapshot
    );
    const idsToSkip = Object.keys(profiles);
    const collectionRef = db.collection('games').where('questId', '==', data);
    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let games = yield call(convertCollectionsSnapshotToMap, snapshots);
    const gameIds = Object.keys(games);
    for (let iGame = 0; iGame < gameIds.length; iGame++) {
      const gameId = gameIds[iGame];
      const game = games[gameId];
      const playerId = game.masterUserId;
      for (let iDev = 0; iDev < idsToSkip.length; iDev++) {
        if (idsToSkip[iDev] === playerId) {
          delete games[gameId];
        }
      }
      let playTime = 0;
      if (game.startTime > 0 && game.endTime > 0) {
        playTime = game.endTime - game.startTime;
      }
      game.playTime = playTime;
    }

    yield put(actions.loadGamesSuccess(games));
  } catch (error) {
    yield put(actions.loadGamesError(error));
  }
}

function* loadSingleGame(datas) {
  const gameId = datas.payload.data.gameId;
  try {
    const documentRef = db.collection('games').doc(`${gameId}`);
    const docSnapshot = yield call(rsf.firestore.getDocument, documentRef);
    const data = docSnapshot.data();
    const collectionPlayersRef = db
      .collection('games')
      .doc(gameId)
      .collection('players');
    const playerSnapshot = yield call(
      rsf.firestore.getCollection,
      collectionPlayersRef
    );
    const player = yield call(convertCollectionsSnapshotToMap, playerSnapshot);
    const teamId = Object.values(player)[0].teamId;
    const collectionEventRef = db
      .collection('games')
      .doc(gameId)
      .collection('teams')
      .doc(teamId)
      .collection('events');
    const eventSnapshot = yield call(
      rsf.firestore.getCollection,
      collectionEventRef
    );
    const events = yield call(convertCollectionsSnapshotToMap, eventSnapshot);

    const collectionLocationsRef = db
      .collection('games')
      .doc(gameId)
      .collection('locations');
    const locationsSnapshot = yield call(
      rsf.firestore.getCollection,
      collectionLocationsRef
    );
    const locations = yield call(
      convertCollectionsSnapshotToMap,
      locationsSnapshot
    );

    const gameData = { events, data, locations };
    yield put(actions.loadSingleGameSuccess(gameData));
  } catch (error) {
    console.log(error);
    yield put(actions.loadSingleGameError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_GAMES, loadGames),
    takeEvery(actions.LOAD_SINGLE_GAME, loadSingleGame),
    takeEvery(actions.LOAD_STATS, loadStats),
  ]);
}
