import log from "../../common/services/LogService";

export default abstract class AGameSubManager {
  /**
   * Consolidate submanager datas by
   * @param {*} scenario
   */
  abstract consolidateData(scenario: any): void;

  /**
   * get all submanager actions
   * @param {*} scenario
   */
  abstract getActions(scenario: any, allActionsMap: any): void;

  /**
 * get all submanager triggers
 * @param {*} scenario
 */
  abstract getTriggers(scenario: any): void;

  fillActionScenarioPath(action: any) {

    const scenarioPath = this.getActionScenarioPath(action);
    if (scenarioPath) {
      action.scenarioPath = scenarioPath;
    } else {
      log.error(
        "Error while setting action scenario path"
      );
      return;
    }
  }

  /**
 * Get the action path in the scenario
 * @param action
 */
  abstract getActionScenarioPath(action: any): string | undefined;
}

