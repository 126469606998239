const actions = {
  LOAD_HERITAGES: 'LOAD_HERITAGES',
  LOAD_HERITAGES_SUCCESS: 'LOAD_HERITAGES_SUCCESS',
  LOAD_ERROR: 'LOAD_ERROR',
  TOGGLE_MODAL_HERITAGE: 'TOGGLE_MODAL_HERITAGE',
  INPUT_HERITAGE: 'INPUT_HERITAGE',
  CREATE_HERITAGE: 'CREATE_HERITAGE',
  CREATE_HERITAGE_SUCCESS: 'CREATE_HERITAGE_SUCCESS',
  UPDATE_HERITAGE: 'UPDATE_HERITAGE',
  UPDATE_HERITAGE_SUCCESS: 'UPDATE_HERITAGE_SUCCESS',
  DELETE_HERITAGE: 'DELETE_HERITAGE',

  loadHeritages: reference => ({
    type: actions.LOAD_HERITAGES,
    payload: { reference },
  }),

  loadHeritagesSuccess: data => ({
    type: actions.LOAD_HERITAGES_SUCCESS,
    payload: { data },
  }),

  loadError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  toggleModalHeritage: (data = null) => ({
    type: actions.TOGGLE_MODAL_HERITAGE,
    payload: { data },
  }),

  inputHeritage: data => ({
    type: actions.INPUT_HERITAGE,
    payload: { data },
  }),

  createHeritage: data => ({
    type: actions.CREATE_HERITAGE,
    payload: data,
  }),

  createHeritageSuccess: data => ({
    type: actions.CREATE_HERITAGE_SUCCESS,
    payload: { data },
  }),

  updateHeritage: data => ({
    type: actions.UPDATE_HERITAGE,
    payload: data,
  }),

  updateHeritageSuccess: data => ({
    type: actions.UPDATE_HERITAGE_SUCCESS,
    payload: { data },
  }),

  deleteHeritage: (heritageData, reference) => ({
    type: actions.DELETE_HERITAGE,
    payload: { heritageData, reference },
  }),
};

export default actions;
