import actions from '../actions/actions';

const initialState = {
  isLoading: false,
  errorMessages: false,
  quests: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.LOAD_QUESTS:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.LOAD_QUESTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        quests: payload.data,
        errorMessages: false,
      };
    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessages: payload.error,
      };
    default:
      return state;
  }
}
