import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './root-reducer';
import rootSaga from './root-saga';

export type AppState = ReturnType<typeof rootReducer>;

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];

const enhancers = [];
const windowIfDefined =
  typeof window === 'undefined' ? null : (window as any);
if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middlewares), ...enhancers)
);
sagaMiddleware.run(rootSaga);
export { store };
