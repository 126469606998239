import GameObject from "../../granaquest-library/bag/models/GameObject";
import Scenario from "../../granaquest-library/scenarios/models/Scenario";
import AGameSubManager from "../../Scenarios/services/AGameSubManager";
import GameObjectExt from "../models/GameObjectExt";
import { getStoragePublicPath } from "@iso/lib/firebase/firebase.util";
import NodeModel from "../../Scenarios/models/NodeModel";

// Get all triggers from scenario
export function getObjectsState(
  questKey: string,
  scenarioId: string,
  scenario: Scenario
) {
  let objects: { [key: string]: GameObject } = {};
  if (scenario.objects) {
    objects = scenario.objects;
  }

  // TODO: move
  for (const objectsId in objects) {
    const object = objects[objectsId] as GameObjectExt;
    if (object.img) {
      object.imgUrl = getStoragePublicPath(
        `quests/${questKey}/${scenarioId}/objects/${object.img}`
      );
    }
  }
  const objectsState = {
    data: objects,
    object: null,
    modalActive: false,
    isLoading: false,
  };

  return objectsState;
}

/**
 * TODO
 * @param editedNode
 * @param node
 */
export function createObjectNewNodesAndEdges(
  editedNode: any,
  objectToState: any,
  node: any,
  creation: any,
  checked: any
) {
  let objNodes: any = {
    [editedNode.id]: {
      ...editedNode,
      data: {
        ...editedNode.data,
        infos: checked,
      },
    },
  };
  let objEdges: any = [];
  if (creation) {
    //Edge to source.
    const edgeSourceToNewNode = {
      id: `${node.id}_to_${editedNode.id}`,
      source: node.id,
      target: editedNode.id,
      type: "triggerActionEdge",
    };

    objEdges = { [edgeSourceToNewNode.id]: edgeSourceToNewNode };
  }
  //Unlock Trigger:
  if (objectToState.unlock && objectToState.unlock !== null) {
    const unlockCode = objectToState.unlock.toString();
    const path = `objects.${editedNode.data.objectIds[0]}.triggers.unlock.${unlockCode}`;
    console.log(objectToState.unlock);
    const unlockData = {
      type: "triggerNode",
      id: `${editedNode.id}_Unlock_trigger`,
      data: {
        requireActions: [editedNode.id],
        scenarioPath: path.toString(),
        triggerType: "UnlockTriggerModel",
        id: `${editedNode.id}_Unlock_trigger`,
      },
    };
    // create Edge  to trigger:
    const NewNodeToUnlock = {
      id: `${editedNode.id}_to_${unlockData.id}`,
      target: unlockData.id,
      source: editedNode.id,
      type: "requiredActionEdge",
    };
    objNodes = {
      ...objNodes,
      [unlockData.id]: unlockData,
    };
    objEdges = {
      ...objEdges,
      [NewNodeToUnlock.id]: NewNodeToUnlock,
    };
  }
  console.log(checked);
  //If combine
  if (checked.combine) {
    const combineData = {
      type: "triggerNode",
      id: `${editedNode.id}_combine_trigger`,
      data: {
        requireActions: [editedNode.id],
        scenarioPath: `objects.${editedNode.data.objectIds[0]}.triggers.combine.${objectToState.allowedActions.combine}`,
        triggerType: "CombineObjectsTriggerModel",
        id: `${editedNode.id}_combine_trigger`,
      },
    };

    const NewNodeToCombine = {
      id: `${editedNode.id}_to_${combineData.id}`,
      target: combineData.id,
      source: editedNode.id,
      type: "requiredActionEdge",
    };

    objNodes = {
      ...objNodes,
      [combineData.id]: combineData,
    };
    objEdges = {
      ...objEdges,
      [NewNodeToCombine.id]: NewNodeToCombine,
    };
  }

  return {
    objNodes,
    objEdges,
  };
}

//Function to generate AllowedAction modification.
function generaAllowedAction() {
  //Return
}

export default class ObjectService extends AGameSubManager {
  /**
   * Consolidate objects datas by
   * @param {*} scenario
   */
  consolidateData(scenario: Scenario): void {
    // For each object, extract needed properties from triggers
    if (scenario.objects) {
      for (let objectId in scenario.objects) {
        const gameObject = scenario.objects[objectId] as GameObjectExt;
        if (gameObject.triggers) {
          if (gameObject.triggers.unlock) {
            gameObject.unlockCodes = [];
            // Loop on unlock triggers
            for (let code in gameObject.triggers.unlock) {
              console.log(`Code ${code} for object ${objectId}`);
              gameObject.unlockCodes.push(code);
            }
          } else if (gameObject.triggers.combine) {
            gameObject.combine = [];
            //loop on combine triggers
            for (let combine in gameObject.triggers.combine) {
              gameObject.combine.push(combine);
            }
          } else if (
            gameObject.allowedActions?.playsound ||
            gameObject.allowedActions?.playvideo
          ) {
            gameObject.media = [];
            if (gameObject.allowedActions.playsound) {
              for (let sound in gameObject.allowedActions.playsound) {
                gameObject.media.push(sound);
              }
            } else {
              for (let video in gameObject.allowedActions.playvideo) {
                gameObject.media.push(video);
              }
            }
          }
        }
      }
    }
  }

  /**
 * Get the action path in the scenario
 * @param action
 */
  getActionScenarioPath(action: any): string | undefined {
    // TODO: To be implemented
    return
  }

  /**
   * get all character actions
   * @param {*} scenario
   */
  getActions(scenario: Scenario, allActionsMap: { [key: string]: any }): void {
    //TODO: later
  }

  /**
   * get all character triggers
   * @param {*} scenario
   */
  getTriggers(scenario: Scenario): void {
    //TODO: later
  }
}

export function findNodeAndEdgeToDelete(
  node: NodeModel,
  editedNode: NodeModel
) { }

export function createNodeAndEdges(node: NodeModel, editedNode: NodeModel) { }
