import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from '../actions/actions';
import { rsf } from '@iso/lib/firebase/firebase';
import log from '../../common/services/LogService';
import UserModel from '../../granaquest-library/authentication/models/UserModel';

// TODO: type action
function* loadUserProfile(props: any) {
  try {
    const userId = props.payload.data;
    log.info(`Loading user profile for user ${userId}`);
    const snapshotValue = yield call(
      rsf.firestore.getDocument,
      `users/${userId}`
    );
    let user: UserModel = snapshotValue.data();

    if (user) {
      log.info(`Loaded user profile for user ${userId}: ${JSON.stringify(user)}`);
      yield put(actions.loadUserProfileSuccess(user));
    } else {
      // TODO: crashlytics
      log.error(`User profile not found for user ${userId}`);
      yield put(actions.loadUserProfileError('Null snapshot value'));
    }
  } catch (error) {
    yield put(actions.loadUserProfileError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_USER_PROFILE, loadUserProfile)]);
}
