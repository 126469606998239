
enum ActionType {
    AddBonusMalusActionModel = "AddBonusMalusActionModel",
    AskQuestionActionModel = "AskQuestionActionModel",
    DialogActionModel = "DialogActionModel",
    DisplayChapterLocationActionModel = "DisplayChapterLocationActionModel",
    EndChapterActionModel = "EndChapterActionModel",
    GoToScreenActionModel = "GoToScreenActionModel",
    PlaySoundtrackActionModel = "PlaySoundtrackActionModel",
    PlayVideoActionModel = "PlayVideoActionModel",
    ReceiveObjectsActionModel = "ReceiveObjectsActionModel",
    ShowInstructionActionModel = "ShowInstructionActionModel",
    ToggleARActionModel = "ToggleARActionModel",
    ToggleCharacterActionModel = "ToggleCharacterActionModel",
    ToggleGeofenceActionModel = "ToggleGeofenceActionModel",
    ToggleNFCActionModel = "ToggleNfcActionModel",
    ToggleObjectsActionModel = "ToggleObjectsActionModel",
}
export default ActionType
