import actions from '../actions/actions';

const initialState = {
  isLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.ADD_INSTRUCTION:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ADD_INSTRUCTION_SUCCESS:
      console.log(payload);

      let id;
      if (payload.instruction.id) {
        id = payload.instruction.id;
      } else {
        id = Math.random()
          .toString(36)
          .substr(2, 9);
        payload.id = id;
      }
      let instruction = payload.instruction;
      let pictureName;
      if (payload.picture) {
        pictureName = payload.picture.name;
        instruction.pages[0].img = pictureName;
      }
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [id]: { ...instruction },
        },
      };

    default:
      return state;
  }
}
