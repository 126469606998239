export interface TypedNodeModel<T> {
  id: string;
  type: NodeType;
  title?: string;
  headerText?: string;
  /**
   * To be renamed in picture
   */
  icon?: string;
  data: NonNullable<T>;
}

export interface BaseNodeData {
  id?: string;
  triggerActions?: string[];
  scenarioPath?: string;
}

export default interface NodeModel extends TypedNodeModel<any> { }

export enum NodeType {
  TRIGGER_NODE = "triggerNode",
  ACTION_NODE = "actionNode",
  INIT_CHAPTER_NODE = "chapter",
}
