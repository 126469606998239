const actions = {
  LOAD_QUESTS: 'LOAD_QUESTS',
  LOAD_QUESTS_SUCCESS: 'LOAD_QUESTS_SUCCESS',
  LOAD_ERROR: 'LOAD_ERROR',

  loadQuests: profile => ({
    type: actions.LOAD_QUESTS,
    payload: { profile },
  }),

  loadQuestsSuccess: data => ({
    type: actions.LOAD_QUESTS_SUCCESS,
    payload: { data },
  }),

  loadError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),
};

export default actions;
