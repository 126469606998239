import EdgeModel, { EdgeType } from "../../Scenarios/models/EdgeModel";
import NodeModel, {
  NodeType,
  TypedNodeModel,
} from "../../Scenarios/models/NodeModel";
import AGameSubManager from "../../Scenarios/services/AGameSubManager";
import cloneDeep from "lodash/cloneDeep";
import Scenario from "../../granaquest-library/scenarios/models/Scenario";
import log from "../../common/services/LogService";
import DialogActionModel from "../../granaquest-library/characters/models/actions/DialogActionModel";
import BaseTriggerModel from "../../granaquest-library/triggers/models/triggers/BaseTriggerModel";
import GameCharacter from "../../granaquest-library/characters/models/GameCharacter";
import { getStoragePublicPath } from "@iso/lib/firebase/firebase.util";
import { getScenarioStorageRelativePath } from "../../Scenarios/services/ScenarioService";
import AskQuestionActionModel from "../../granaquest-library/characters/models/actions/AskQuestionActionModel";
import AskQuestionActionModelExt from "../models/AskQuestionActionModelExt";
import BaseCharacterActionModel from "../models/BaseCharacterActionModel";
import ActionType from "../../granaquest-library/games/models/actions/ActionType";
//Consolidate.
// pour retrouver les reponses.
//

export function getCharactersState(
  questKey: string,
  scenarioId: string,
  scenario: any
) {
  let characters: any = {};
  if (scenario.characters) {
    characters = scenario.characters;
  }

  for (const characterId in characters) {
    const character = characters[characterId];
    if (character.avatar) {
      character.avatarUrl = `https://storage.googleapis.com/granaquestbackend.appspot.com/quests/${questKey}/${scenarioId}/characters/${character.avatar}`;
    }
  }
  const charactersState = {
    data: characters,
    character: null,
    modalActive: false,
    isLoading: false,
  };

  return charactersState;
}

const RamDomId = Math.random().toString(36).substr(2, 9);

export function DialogCharacterNodeAndEdgeMaker(payload: any) {
  const { editedNode, node, creation } = payload;

  let edges = {};
  let nodes = {};

  const edgeSourceToNewNode = {
    id: `${node.id}_to_${editedNode.id}`,
    source: node.id,
    target: editedNode.id,
    type: "triggerActionEdge",
  };

  nodes = {
    ...nodes,
    [editedNode.id]: editedNode,
  };

  if (creation) {
    edges = {
      ...edges,
      [edgeSourceToNewNode.id]: edgeSourceToNewNode,
    };
  }

  if (editedNode.data.receiveObjectId) {
    const nodeGiveObject = {
      id: `${editedNode.data.receiveObjectId}_activate_${RamDomId}`,
      type: "actionNode",
      data: {
        id: `${editedNode.data.receiveObjectId}_activate_${RamDomId}`,
        scenarioPath: `characters.${editedNode.data.characterId}.actions.receiveObjects.${editedNode.data.receiveObjectId}_activate_${RamDomId}`,
        characterId: editedNode.data.characterId,
        objectIds: [editedNode.data.receiveObjectId],
        actionType: "ReceiveObjectsActionModel",
      },
    };

    const edgeToNewNodeToGiveObject = {
      id: `${editedNode.id}_to_${nodeGiveObject.id}`,
      source: editedNode.id,
      target: nodeGiveObject.id,
      type: "triggerActionEdge",
    };
    const triggerId = `objects.${payload.editedNode.data.receiveObjectId}.triggers.FirstViewTriggerModel`;

    const triggerFirstView = {
      id: `FirstView_${RamDomId}`,
      type: "triggerNode",
      data: {
        triggerType: "FirstViewTriggerModel",
        id: `FirstView_${RamDomId}`,
        scenarioPath: triggerId,
      },
    };

    const edgeToGiveObjectToTrigger = {
      id: `${nodeGiveObject.id}_to_${triggerFirstView.id}`,
      source: nodeGiveObject.id,
      target: triggerFirstView.id,
      type: "triggerActionEdge",
    };

    nodes = {
      ...nodes,
      [nodeGiveObject.id]: nodeGiveObject,
      [triggerFirstView.id]: triggerFirstView,
    };
    edges = {
      ...edges,
      [edgeToNewNodeToGiveObject.id]: edgeToNewNodeToGiveObject,
      [edgeToGiveObjectToTrigger.id]: edgeToGiveObjectToTrigger,
    };
  }
  return {
    objNodes: nodes,
    objEdges: edges,
  };
}

export function toggleCharacterNodeAndEdgeMaker(payload: any) {
  // Le nodeGraph
  //Edge to newnode
  let objNodes;
  let objEdges;

  const { editedNode, node, creation } = payload;
  const triggerId = `characters.${editedNode.data.characterId}.triggers.closeActivationModal`;

  const edgeSourceToToggleCharacter = {
    source: node.id,
    target: editedNode.id,
    type: "triggerActionEdge",
    id: `${node.id}_to_${editedNode.id}`,
  };
  //+ Le nouveau Noeud
  const toggleCharacterNode = editedNode;

  // trigger to Close
  const triggerCloseActionModel = {
    type: "triggerNode",
    id: `${triggerId}_${RamDomId}`,
    data: {
      requireActions: [],
      scenarioPath: triggerId,
      triggerType: "CloseActivationModalTriggerModel",
      id: `${triggerId}_${RamDomId}`,
    },
  };

  // + Le edge to close
  const edgeToTrigger = {
    source: editedNode.id,
    target: triggerCloseActionModel.id,
    id: `${triggerId}_${RamDomId}`,
    type: "requiredActionEdge",
  };

  objNodes = {
    [toggleCharacterNode.id]: toggleCharacterNode,
  };

  if (creation) {
    objNodes = {
      ...objNodes,
      [triggerCloseActionModel.id]: triggerCloseActionModel,
    };
  }

  objEdges = {
    [edgeSourceToToggleCharacter.id]: edgeSourceToToggleCharacter,
    [edgeToTrigger.id]: edgeToTrigger,
  };

  if (editedNode.data.objectIds) {
    for (let objectId in editedNode.data.objectIds) {
      //Create a Trigger.
      const triggerGiveObject = {
        id: `${editedNode.id}_giveObject_${objectId}`,
        type: "triggerNode",
        data: {
          triggerType: "GiveObjectTriggerModel",
          keepiInBag: false,
          unblockPriority: 0,
          id: `${editedNode.id}_giveObject_${objectId}`,
          triggerActions: [],
          requireActions: [editedNode.id, `${objectId}_activate`],
          scenarioPath: `characters.${editedNode.data.characterId}.triggers.giveObjects.${objectId}`,
        },
      };
      // Create an Edge
      const edgeNodeToTriggerObject = {
        id: `${editedNode.id}_to_${triggerGiveObject.id}`,
        source: editedNode.id,
        target: triggerGiveObject.id,
        type: "requiredActionEdge",
      };

      objNodes = {
        ...objNodes,
        [triggerGiveObject.id]: triggerGiveObject,
      };

      objEdges = {
        ...objEdges,
        [edgeNodeToTriggerObject.id]: edgeNodeToTriggerObject,
      };
    }
  }

  return {
    objNodes,
    objEdges,
  };
}

export function createNodeAndEdgeAskQuestion(payload: {
  editedNode: TypedNodeModel<AskQuestionActionModelExt>;
  node: TypedNodeModel<AskQuestionActionModelExt>;
}) {
  const { node } = payload;
  const editedNode = cloneDeep(payload.editedNode);

  // TODO: why use answerChoices and answerChoicesData
  //answerChoices: informations without true or false.
  //AnswerChoicesData: all informations to edit question (can't save in scenario => Crash game).
  editedNode.data.answerChoices = {};
  for (let answer in editedNode.data.answerChoicesData) {
    editedNode.data.answerChoices[answer] = {
      fr: editedNode.data.answerChoicesData[answer].fr,
    };
  }

  let objEdges = {};
  let objNodes = {
    [editedNode.id]: editedNode,
  };
  //create Edge
  const edgeSourceToNewNode: EdgeModel = {
    id: `${node.id}_to_${editedNode.id}`,
    source: node.id,
    target: editedNode.id,
    type: EdgeType.TRIGGER_ACTION_EDGE,
  };

  objEdges = {
    ...objEdges,
    [edgeSourceToNewNode.id]: edgeSourceToNewNode,
  };

  for (let answerId in editedNode.data.answerChoices) {
    const answer = editedNode.data.answerChoices[answerId];
    let newTrigger: NodeModel = {
      id: `${answerId}`,
      type: NodeType.TRIGGER_NODE,
      data: {
        triggerType: "AnswerSelectedTriggerModel",
        unblockPriority: 0,
        id: `${answerId}`,
        scenarioPath: `characters.${editedNode.data.characterId}.triggers.answerMultipleChoicesQuestions.${editedNode.id}.${answerId}`,
        triggerActions: [],
        requireActions: [editedNode.id],
        standardBonusMalus: answer.goodChoice ? "defaultPenalty" : null,
      },
    };

    const edgesNodeToTrigger = {
      id: `${editedNode.id}_to_${newTrigger.id}`,
      source: editedNode.id,
      target: newTrigger.id,
      type: "requiredActionEdge",
    };
    objNodes = {
      ...objNodes,
      [newTrigger.id]: newTrigger,
    };
    objEdges = {
      ...objEdges,
      [edgesNodeToTrigger.id]: edgesNodeToTrigger,
    };
  }

  return {
    objEdges,
    objNodes,
  };
}

class CharacterService extends AGameSubManager {
  CORRECT_ANSWER_KEY = "correctAnswer";

  /**
   * Consolidate character datas by
   * @param {*} scenario
   */
  consolidateData(scenario: any): void { }
  // Parcourir action
  // action type askquestion
  // Parcourir les triggers et recuperer les reponses. (data).

  /**
   * Get the action path in the scenario
   * @param action
   */
  getActionScenarioPath(action: BaseCharacterActionModel): string | undefined {

    if (!action || !action.characterId || !action.id) {
      log.error(
        "Error while setting character action scenario path: null characterId or dialogKey"
      );
      return;
    }

    switch (action.actionType) {
      case ActionType.DialogActionModel:
        return `characters.${action.characterId}.actions.dialogs.${action.id}`;
      case ActionType.ToggleCharacterActionModel:
        return `characters.${action.characterId}.actions.toggleCharacters.${action.id}`;
    }
  }

  /**
   *
   * @param character The character
   */
  getCharacterAvatarUrl(
    questKey: string,
    scenarioId: string,
    character: GameCharacter
  ): string {
    return getStoragePublicPath(
      getScenarioStorageRelativePath(
        questKey,
        scenarioId,
        `characters/${character.avatar}`
      )
    );
  }

  /**
   * Get all characters actions and fill the given map with it
   * @param scenario The scenario
   * @param allActionsMap The action map
   */
  getActions(
    scenario: Scenario,
    allActionsMap: { [key: string]: BaseTriggerModel }
  ): void {
    if (scenario && scenario.characters) {
      for (let characterId in scenario.characters) {
        const character = scenario.characters[characterId];
        log.debug(`Adding character ${character.id} actions`);
        this.getDialogActions(character, allActionsMap);
        this.getToggleActions(character, allActionsMap);
        this.getAskQuestionActions(character, allActionsMap);
      }
    }
  }

  /**
   * Extract the dialog actions from a character
   * @param character
   * @param allActionsMap 
   */
  getDialogActions(
    character: GameCharacter,
    allActionsMap: { [key: string]: BaseTriggerModel }
  ): void {

    let nActions = 0;
    if (character.actions && character.actions.dialogs) {
      const actions = character.actions;
      for (let key in actions.dialogs) {
        const action = actions.dialogs[key];
        if (!action.id) {
          action.id = key;
        }
        if (action.id) {
          nActions++
          // Reset action properties in case note defined in the scenario
          action.characterId = character.id;
          action.actionType = ActionType.DialogActionModel;
          log.debug(`Adding dialog action ${key}`);
          // Set scenario path
          this.fillActionScenarioPath(action);
          // dialogAction.importedFromService = true;
          allActionsMap[action.id] = action;
        } else {
          log.warn(`No id for Dialog action in path`);
        }
      }
    }
    log.debug(`Adding ${nActions} character ${character.id} dialog actions`);
  }

  /**
 * Extract the toggle actions from a character
 * @param character
 * @param allActionsMap 
 */
  getToggleActions(
    character: GameCharacter,
    allActionsMap: { [key: string]: BaseTriggerModel }
  ): void {

    let nActions = 0;
    if (character.actions && character.actions.toggleCharacters) {
      for (let key in character.actions.toggleCharacters) {
        const action = character.actions.toggleCharacters[key];
        if (!action.id) {
          action.id = key;
        }
        if (action.id) {
          nActions++
          // Reset action properties in case note defined in the scenario
          action.characterId = character.id;
          action.actionType = ActionType.ToggleCharacterActionModel;
          log.debug(`Adding toggle action ${key}`);
          // Set scenario path
          this.fillActionScenarioPath(action);
          // dialogAction.importedFromService = true;
          allActionsMap[action.id] = action;
        } else {
          log.warn(`No id for toggle action in path`);
        }
      }
    }
    log.debug(`Adding ${nActions} character ${character.id} toggle actions`);
  }

  /**
* Extract the ask questions actions from a character
* @param character
* @param allActionsMap 
*/
  getAskQuestionActions(
    character: GameCharacter,
    allActionsMap: { [key: string]: BaseTriggerModel }
  ): void {

    let nActions = 0;
    if (character.actions && character.actions.askQuestions) {
      for (let key in character.actions.askQuestions) {
        const action = character.actions.askQuestions[key];
        if (!action.id) {
          action.id = key;
        }
        if (action.id) {
          nActions++
          // Reset action properties in case note defined in the scenario
          action.characterId = character.id;
          action.actionType = ActionType.AskQuestionActionModel;
          log.debug(`Adding ask question action ${key}`);
          // Set scenario path
          this.fillActionScenarioPath(action);
          // dialogAction.importedFromService = true;
          allActionsMap[action.id] = action;
        } else {
          log.warn(`No id for askquestion action in path`);
        }
      }
    }
    log.debug(`Adding ${nActions} character ${character.id} ask question actions`);
  }

  /**
   * get all character triggers
   * @param {*} scenario
   */
  getTriggers(scenario: any): void { }
}

const characterService = new CharacterService();
export default characterService;

export function createNodeAndEdgeAskQuestionFree(payload: any) {
  const { editedNode, exact, node, trigger } = payload;

  let objNodes = {
    [editedNode.id]: editedNode,
  };

  const edgeToSource = {
    id: `${node.id} _to_${editedNode.id} `,
    source: node.id,
    target: editedNode.id,
    type: "triggerActionEdge",
  };

  let objEdges = {
    [edgeToSource.id]: edgeToSource,
  };
  const arrayAnswer: string[] = [];
  Object.values(trigger).map((answer: any) => arrayAnswer.push(answer.fr));

  //Trigger
  const triggerNode = {
    id: `${editedNode.id} _triggers`,
    type: "triggerNode",
    data: {
      id: `${editedNode.id} _triggers`,
      triggerType: "AnswerTextTriggerModel",
      scenarioPath: `characters.${editedNode.data.characterId}.triggers.answerFreeTextQuestions.${editedNode.id}.correctAnswer`,
      triggerActions: [],
      requireActions: [editedNode.id],
      acceptedAnswers: { fr: arrayAnswer.join() },
      exactMatch: exact,
    },
  };

  objNodes = {
    ...objNodes,
    [triggerNode.id]: triggerNode,
  };

  const edgeEditNodeToTrigge = {
    id: `${editedNode.id} _to_${triggerNode.id} `,
    source: editedNode.id,
    target: triggerNode.id,
    type: "requiredActionEdge",
  };

  objEdges = {
    ...objEdges,
    [edgeEditNodeToTrigge.id]: edgeEditNodeToTrigge,
  };
  return {
    objEdges,
    objNodes,
  };
}

export function createNodeAndEdgeAskQuestionNumber(payload: any) {
  const { editedNode, node, answer } = payload;

  let objNodes = {
    [editedNode.id]: editedNode,
  };

  const edgeTosource = {
    id: `${node.id} _To_${editedNode.id} `,
    source: node.id,
    target: editedNode.id,
    type: "triggerActionEdge",
  };

  let trigger = {
    id: `${editedNode.id} _trigger`,
    type: "triggerNode",
    data: {
      id: `${editedNode.id} _trigger`,
      scenarioPath: `characters.${editedNode.data.characterId}.triggers.answerFreeTextQuestions.${editedNode.id}.correctAnswer`,
      triggerType: "AnswerTextTriggerModel",
      requireActions: [editedNode.id],
      triggerActions: [],
      value: parseInt(answer.solution),
      tolerance: 0,
    },
  };
  if (answer.tolerance) {
    trigger = {
      ...trigger,
      data: {
        ...trigger.data,
        tolerance: parseInt(answer.tolerance),
      },
    };
  }

  const edgeNodeToTrigger = {
    id: `${editedNode.id} _To_${trigger.id} `,
    source: editedNode.id,
    target: trigger.id,
    type: "requiredActionEdge",
  };

  objNodes = {
    ...objNodes,
    [trigger.id]: trigger,
  };

  const objEdges = {
    [edgeTosource.id]: edgeTosource,
    [edgeNodeToTrigger.id]: edgeNodeToTrigger,
  };

  return {
    objNodes,
    objEdges,
  };
}
