const actions = {
  LOAD_STATS: 'LOAD_STATS',
  LOAD_STATS_SUCCESS: 'LOAD_STATS_SUCCESS',
  LOAD_STATS_ERROR: 'LOAD_STATS_ERROR',
  LOAD_GAMES: 'LOAD_GAMES',
  LOAD_GAMES_SUCCESS: 'LOAD_GAMES_SUCCESS',
  LOAD_GAMES_ERROR: 'LOAD_GAMES_ERROR',
  LOAD_SINGLE_GAME: 'LOAD_SINGLE_GAME',
  LOAD_SINGLE_GAME_SUCCESS: 'LOAD_SINGLE_GAME_SUCCESS',
  LOAD_SINGLE_GAME_ERROR: 'LOAD_SINGLE_GAME_ERROR',

  loadStats: data => ({
    type: actions.LOAD_STATS,
    payload: { data },
  }),
  loadStatsSuccess: data => ({
    type: actions.LOAD_STATS_SUCCESS,
    payload: { data },
  }),
  loadStatsError: error => ({
    type: actions.LOAD_STATS_ERROR,
    payload: { error },
  }),

  loadGamesError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  loadGames: data => ({
    type: actions.LOAD_GAMES,
    payload: { data },
  }),

  loadGamesSuccess: data => ({
    type: actions.LOAD_GAMES_SUCCESS,
    payload: { data },
  }),

  loadSingleGame: data => ({
    type: actions.LOAD_SINGLE_GAME,
    payload: { data },
  }),

  loadSingleGameSuccess: data => ({
    type: actions.LOAD_SINGLE_GAME_SUCCESS,
    payload: { data },
  }),

  loadSingleGameError: error => ({
    type: actions.LOAD_SINGLE_GAME_ERROR,
    payload: { error },
  }),
};

export default actions;
