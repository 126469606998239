import { all, put, call, takeEvery, select } from 'redux-saga/effects';
import actions, { LoadSingleQuestAction, UpdateQuestAction, UpdateQuestPictureAction, UpdateQuestSuccessAction } from '../actions/actions';
import { getPicture } from './selectors';
import { rsf } from '@iso/lib/firebase/firebase';
import firebase from 'firebase/app';
import QuestModel from '../../granaquest-library/quests/models/QuestModel';
import scenarioService from '../../Scenarios/services/ScenarioService';
import { getStoragePublicPath } from "@iso/lib/firebase/firebase.util";
import log from '../../common/services/LogService';

function* loadSingleQuest(action: LoadSingleQuestAction) {
  try {
    const snapshot = yield call(
      rsf.firestore.getDocument,
      `quests/${action.payload.questKey}`
    );
    const quest: QuestModel = snapshot.data();
    yield put(actions.loadSingleQuestSuccess(quest));
  } catch (error) {
    log.error(error);
    yield put(actions.loadError(error));
  }
}

function* updateQuest(action: UpdateQuestAction) {
  const { quest } = action.payload;
  try {
    // Update the firestore document
    // Build a firebase.firestore.GeoPoint to be able to execute spatial queries
    yield call(
      rsf.firestore.setDocument,
      `quests/${quest.name}`,
      {
        ...quest,
        location: (quest.location && quest.location.latitude && quest.location.longitude) ? new firebase.firestore.GeoPoint(
          quest.location.latitude,
          quest.location.longitude
        ) : undefined,
      },
      { merge: true }
    );
    yield put(actions.updateQuestSuccess(quest));
  } catch (error) {
    log.error(error);
    // TODO: use a generic error
    yield put(actions.loadError(error));
  }
}

function* updateQuestPicture(action: UpdateQuestPictureAction) {
  const { data } = action.payload;
  const state = yield select(getPicture);
  const pathname = `quests/${state.quest.name}`;
  // TODO: handle jpg or png
  const picturePath = `${pathname}/thumbnail.jpg`;
  try {
    if (data !== null) {

      const task = rsf.storage.uploadFile(
        picturePath,
        data,
        scenarioService.DEFAULT_STORAGE_METADATA);
      yield task;

      // Get the storage public url using google cloud bucket public access
      const url = getStoragePublicPath(picturePath);

      // Update the quest with the new url
      // TODO: as the url does not change now, still needed ?
      yield call(
        rsf.firestore.setDocument,
        pathname,
        { picture: url },
        { merge: true }
      );
      const snapshot = yield call(rsf.firestore.getDocument, pathname);
      const quest: QuestModel = snapshot.data();
      yield put(actions.updateQuestSuccess(quest));
    }
  } catch (error) {
    log.error(error);
    yield put(actions.loadError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_SINGLE_QUEST, loadSingleQuest),
    takeEvery(actions.UPDATE_QUEST, updateQuest),
    takeEvery(actions.UPDATE_QUEST_PICTURE, updateQuestPicture),
  ]);
}
