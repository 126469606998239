const actions = {
  HANDLE_MODAL: 'HANDLE_MODAL',

  handleModal: data => ({
    type: actions.HANDLE_MODAL,
    payload: data,
  }),
};

export default actions;
