import actions from '../actions/actions';

const initialState = {
  isLoading: false,
  errorMessages: false,
  soundTracks: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.LOAD_SOUNDTRACKS:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.LOAD_SOUNDTRACKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        soundtracks: payload.data,
        errorMessages: false,
      };
    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessages: payload.error,
      };
    case actions.TOGGLE_MODAL_SOUNDTRACK:
      return {
        ...state,
        modalActive: !state.modalActive,
        soundtrack:
          payload.data == null ? initialState.soundtrack : payload.data,
      };
    case actions.CREATE_SOUNDTRACK:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.CREATE_SOUNDTRACK_SUCCESS:
      console.log(payload);
      const name = payload.data.payload.soundtrackState.name;
      const id = name.replace('.', '_');
      const { questKey, scenarioId } = payload.data.payload.reference;
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [id]: {
            id: id,
            source: name,
            soundtrackUrl: `https://storage.googleapis.com/granaquestbackend.appspot.com/quests/${questKey}/${scenarioId}/soundtracks/${name}`,
          },
        },
      };
    case actions.UPDATE_SOUNDTRACK:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.UPDATE_SOUNDTRACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {},
        errorMessages: false,
      };
    case actions.DELETE_SOUNDTRACK:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.DELETE_SOUNDTRACK_SUCCESS:
      console.log(payload.payload.soundtrackData.id);
      const newData = state.data;
      for (let sound in newData) {
        if (sound === payload.payload.soundtrackData.id) {
          delete newData[sound];
        }
      }
      console.log(newData);

      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
