export function getinformationsState(questKey, scenarioKey, scenario) {
  let informationsState = {};
  console.log("search itinerary here :");
  console.log(scenario);
  //informations
  informationsState = {
    endGameMessage: scenario.endGameMessage
      ? scenario.endGameMessage
      : { fr: "" },
    duration: scenario.duration ? scenario.duration : 10,
    itinerary: scenario.itinerary ? scenario.itinerary : "",
    theme:
      scenario.theme && scenario.theme.images
        ? scenario.theme
        : {
            images: {
              landscape: { src: "landscape.jpg" },
              portrait: { src: "portrait.jpg" },
            },
          },
    startLocation: scenario.startLocation
      ? scenario.startLocation
      : { longitude: 0, latitude: 0 },
    noChapterPause: scenario.noChapterPause ? scenario.noChapterPause : false,
  };

  return informationsState;
}
