import { all, put, call, takeEvery } from 'redux-saga/effects';
import actions from '../actions/actions';
import { rsf, db } from '@iso/lib/firebase/firebase';
import { convertCollectionsSnapshotToMap } from '@iso/lib/firebase/firebase.util';
import log from '../../common/services/LogService';

const COLLECTION_NAME = 'quests';

function* loadQuestsDatabase(data) {
  const { profile } = data.payload;
  try {
    log.info(`Loading quests for user profile: ${profile ? JSON.stringify(profile) : "undefined"}`);
    const collectionRef = db.collection(COLLECTION_NAME);

    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let allQuests = yield call(convertCollectionsSnapshotToMap, snapshots);

    let filterQuests;
    if (profile !== undefined) {
      filterQuests = [];
      let filterQuestsMap = new Map();
      yield all(
        profile.map(profile => {
          return Object.keys(allQuests).map(quest => {
            if (allQuests[quest].adminProfiles) {
              for (let key in allQuests[quest].adminProfiles) {
                if (!filterQuestsMap.has(quest) && key === profile) {
                  filterQuests.push({ ...allQuests[quest] });
                  filterQuestsMap.set(quest, { ...allQuests[quest] });
                }
              }
            }
            return allQuests[quest];
          });
        })
      );
    }

    yield put(actions.loadQuestsSuccess(filterQuests));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_QUESTS, loadQuestsDatabase)]);
}
