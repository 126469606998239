const actions = {
  LOAD_SOUNDTRACKS: 'LOAD_SOUNDTRACKS',
  LOAD_SOUNDTRACKS_SUCCESS: 'LOAD_SOUNDTRACKS_SUCCESS',
  LOAD_ERROR: 'LOAD_ERROR',
  TOGGLE_MODAL_SOUNDTRACK: 'TOGGLE_MODAL_SOUNDTRACK',
  CREATE_SOUNDTRACK: 'CREATE_SOUNDTRACK',
  CREATE_SOUNDTRACK_SUCCESS: 'CREATE_SOUNDTRACK_SUCCESS',
  UPDATE_SOUNDTRACK: 'UPDATE_SOUNDTRACK',
  UPDATE_SOUNDTRACK_SUCCESS: 'UPDATE_SOUNDTRACK_SUCCESS',
  DELETE_SOUNDTRACK: 'DELETE_SOUNDTRACK',
  DELETE_SOUNDTRACK_SUCCESS: 'DELETE_SOUNDTRACK_SUCCESS',

  loadSoundtracks: reference => ({
    type: actions.LOAD_SOUNDTRACKS,
    payload: { reference },
  }),

  loadSoundtracksSuccess: data => ({
    type: actions.LOAD_SOUNDTRACKS_SUCCESS,
    payload: { data },
  }),

  loadError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  toggleModalSoundtrack: (data = null) => ({
    type: actions.TOGGLE_MODAL_SOUNDTRACK,
    payload: { data },
  }),

  createSoundtrack: (soundtrackState, reference) => ({
    type: actions.CREATE_SOUNDTRACK,
    payload: { soundtrackState, reference },
  }),

  createSoundtrackSuccess: data => ({
    type: actions.CREATE_SOUNDTRACK_SUCCESS,
    payload: { data },
  }),

  updateSoundtrack: (soundtrackState, soundtrackData, reference) => ({
    type: actions.UPDATE_SOUNDTRACK,
    payload: { soundtrackState, soundtrackData, reference },
  }),

  updateSoundtrackSuccess: data => ({
    type: actions.UPDATE_SOUNDTRACK_SUCCESS,
    payload: { data },
  }),

  deleteSoundtrack: (soundtrackData, reference) => ({
    type: actions.DELETE_SOUNDTRACK,
    payload: { soundtrackData, reference },
  }),
  deleteSoundtrackSuccess: data => ({
    type: actions.DELETE_SOUNDTRACK_SUCCESS,
    payload: data,
  }),
};

export default actions;
