import "firebase/firestore";
import ObjectService from "../../Objects/services/ObjectService";
import Scenario from "../../granaquest-library/scenarios/models/Scenario";
import NodeModel, { BaseNodeData, NodeType, TypedNodeModel } from "../models/NodeModel";
import GraphModel from "../models/GraphModel";
import EdgeModel from "../models/EdgeModel";
import BaseTriggerModel from "../../granaquest-library/triggers/models/triggers/BaseTriggerModel";
import { appIntl } from "../../common/components/IntlGlobalProvider";
import characterService from "../../Characters/services/charactersService";
import TriggerType from "../../granaquest-library/triggers/models/triggers/TriggerType";
import ActionType from "../../granaquest-library/games/models/actions/ActionType";
import log from "../../common/services/LogService";
import ChapterModel from "../../granaquest-library/chapters/models/ChapterModel";

export function getScenarioStorageRelativePath(
  questKey: string,
  scenarioId: string,
  relativePath: string
) {
  return `quests/${questKey}/${scenarioId}/${relativePath}`;
}

let nodes: NodeModel[] = [];
let nodesMap: Map<string, NodeModel> = new Map();
let edges: EdgeModel[] = [];
let edgesMap: Map<string, EdgeModel> = new Map();
let test = 0;

function addTriggerOrSubTrigger(
  trigger: any,
  allTriggers: any,
  scenarioPath: any
) {
  if (trigger.triggerType) {
    trigger.scenarioPath = scenarioPath;
    allTriggers[trigger.id] = trigger;
  } else {
    if (typeof trigger === "object") {
      for (let childKey in trigger) {
        const childTrigger = trigger[childKey];
        const childScenarioPath = `${scenarioPath}.${childKey}`;
        addTriggerOrSubTrigger(childTrigger, allTriggers, childScenarioPath);
      }
    }
  }
}

// Get all triggers from scenario
export function getTriggers(data: any, allTriggers: any, scenarioPath?: any) {
  for (let key in data) {
    //TODO : trigger or initTrigger isn't a better solution to find it need to search triggerAction.
    if (key === "trigger" || key === "initTrigger") {
      const childScenarioPath = `${scenarioPath}.${key}`;
      addTriggerOrSubTrigger(data[key], allTriggers, childScenarioPath);
    } else if (key === "triggers") {
      const triggers = data[key];
      for (let triggerKey in triggers) {
        const trigger = triggers[triggerKey];
        const childScenarioPath = `${scenarioPath}.triggers.${triggerKey}`;
        addTriggerOrSubTrigger(trigger, allTriggers, childScenarioPath);
      }
    } else if (typeof data[key] === "object") {
      let childScenarioPath;
      if (!scenarioPath || scenarioPath === "") {
        childScenarioPath = key;
      } else {
        childScenarioPath = `${scenarioPath}.${key}`;
      }

      getTriggers(data[key], allTriggers, childScenarioPath);
    }
  }
}

export function getActionsAndDependingTriggers(
  allActions: any,
  allTriggers: any,
  scenario: Scenario,
  allActionsAndDependingTriggers: any
) {
  for (let triggerKey in allTriggers) {
    const triggerData = allTriggers[triggerKey];
    if (triggerData && triggerData.requireActions) {
      for (
        let iRequireAction = 0;
        iRequireAction < triggerData.requireActions.length;
        iRequireAction++
      ) {
        const requireActionKey = triggerData.requireActions[iRequireAction];
        if (!allActionsAndDependingTriggers.hasOwnProperty(requireActionKey)) {
          allActionsAndDependingTriggers[requireActionKey] = [];
        }
        const dependingTriggerKeys =
          allActionsAndDependingTriggers[requireActionKey];
        if (!dependingTriggerKeys.includes(triggerKey)) {
          dependingTriggerKeys.push(triggerKey);
        }
      }
    }
  }
}

function addActionOrSubAction(action: any, allActions: any, scenarioPath: any) {
  if (action.actionType) {
    action.scenarioPath = scenarioPath;
    // TODO: warn
    allActions[action.id] = action;
  } else {
    if (typeof action === "object") {
      for (let childKey in action) {
        const childAction = action[childKey];
        const childScenarioPath = `${scenarioPath}.${childKey}`;
        addActionOrSubAction(childAction, allActions, childScenarioPath);
      }
    }
  }
}

class ScenarioService {
  gameSubManagers;

  // TODO: to be moved in quest 
  DEFAULT_STORAGE_METADATA = {
    cacheControl: "no-cache, max-age=0",
  };

  constructor() {
    this.gameSubManagers = [];
    this.gameSubManagers.push(characterService);
    this.gameSubManagers.push(new ObjectService());
  }

  getActions(data: any, allActions: any, scenarioPath: any) {
    this.gameSubManagers.forEach((subManager) =>
      subManager.getActions(data, allActions)
    );
  }

  consolidateData(scenario: Scenario) {
    this.gameSubManagers.forEach((subManager) =>
      subManager.consolidateData(scenario)
    );
  }

  /**
   * Get a random id
   * Used for scenario ressources ids (characters, objects, etc.) and actions/trigger ids
   */
  getRandomId(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  /**
   * Find node located above in the graph by going up recursively in the graph
   * @param nodeId  The node id starting point in the graph
   * @param triggerOrActionType The trigger type or action type which must be found
   * @param nodes The list of nodes in the graph
   * @param edges The list of edges in the graph
   */
  findPreviousNode<T>(
    nodeId: string,
    triggerOrActionType: TriggerType | ActionType,
    nodes: { [key: string]: NodeModel },
    edges: { [key: string]: EdgeModel }
  ): TypedNodeModel<T> | null {
    return this.findPreviousMatchingNode<T>(
      nodeId,
      (node: NodeModel) => {
        return (
          node.data.actionType === triggerOrActionType ||
          node.data.triggerType === triggerOrActionType
        );
      },
      nodes,
      edges
    );
  }

  /**
   * Find node located above in the graph by going up recursively in the graph
   * @param nodeId  The node id starting point in the graph
   * @param matchMethod The match method
   * @param nodes The list of nodes in the graph
   * @param edges The list of edges in the graph
   */
  findPreviousMatchingNode<T>(
    nodeId: string,
    matchMethod: (node: NodeModel) => boolean,
    nodes: { [key: string]: NodeModel },
    edges: { [key: string]: EdgeModel }
  ): TypedNodeModel<T> | null {
    for (let edgeId in edges) {
      const edge = edges[edgeId];
      if (edge.target === nodeId) {
        const sourceNode = nodes[edge.source];
        if (sourceNode) {
          if (matchMethod(sourceNode)) {
            return sourceNode as TypedNodeModel<T>;
          } else {
            const foundNode = this.findPreviousMatchingNode(
              sourceNode.id,
              matchMethod,
              nodes,
              edges
            );
            if (foundNode) {
              return foundNode as TypedNodeModel<T>;
            }
          }
        }
      }
    }

    return null;
  }
}
const scenarioService = new ScenarioService();
export default scenarioService;

/**
 * Consolidate data: add properties to scenario model to help editing it
 * @param {*} scenario
 */
export function consolidateData(scenario: Scenario) {
  // Consolidate data
  scenarioService.consolidateData(scenario);
}

// Get all actions from scenario
export function getActions(data: any, allActions: any, scenarioPath: any) {
  if (data) {
    // Add character actions
    scenarioService.getActions(data, allActions, scenarioPath);

    // Blindly add all actions
    for (let key in data) {
      if (key === "actions") {
        const actions = data[key];
        for (let actionKey in actions) {
          const action = actions[actionKey];
          const childScenarioPath = `${scenarioPath}.${key}.${actionKey}`;
          addActionOrSubAction(action, allActions, childScenarioPath);
        }
      } else if (typeof data[key] === "object") {
        let childScenarioPath;
        if (!scenarioPath || scenarioPath === "") {
          childScenarioPath = key;
        } else {
          childScenarioPath = `${scenarioPath}.${key}`;
        }

        getActions(data[key], allActions, childScenarioPath);
      }
    }
  }
}

/**
 * Fill node title and header text based on triggerType or actionType enum value
 * Done here in order to handle localization
 * @param node
 */
export function fillNodeTitles(node: NodeModel) {
  let subType: any;
  if (node.type === NodeType.ACTION_NODE) {
    // TODO: use BaseActionModel when available
    subType = node.data.actionType;
  } else {
    const triggerData = node.data as BaseTriggerModel;
    if (triggerData) {
      subType = node.data.triggerType;
    }
  }

  if (subType) {
    // Fill the title and header title
    const titleMessageId = `node_title_${subType}`;
    const title = appIntl().formatMessage({ id: titleMessageId });
    if (title) {
      node.title = title;
    }
    const headerTextMessageId = `node_header_${subType}`;
    const headerText = appIntl().formatMessage({ id: headerTextMessageId });
    if (headerText) {
      node.headerText = headerText;
    }
  }
}

export function makeGraph(
  scenario: Scenario,
  allTriggers: any,
  allActions: any,
  allActionsAndDependingTriggers: any,
  questKey: string,
  scenarioKey: string
) {
  const chapters: any = scenario.chapters;
  let unusedActions = [];
  const nodesId = [];
  let unusedTriggers = [];
  let position = { x: 0, y: 0 };
  const firstChapterId = chapters?.firstChapterId;
  const firstChapterData =
    chapters && chapters.items && chapters.items[firstChapterId];
  if (nodes.length !== 0 && nodesMap.size !== 0) {
    nodes = [];
    nodesMap.clear();
    edges = [];
    edgesMap.clear();
  }
  addNodeChapterAndInitActions(firstChapterData);

  // TODO: move all this functions
  function addNodeChapterAndInitActions(chapterData: ChapterModel) {
    // TODO: deal with case
    if (chapterData.initTrigger?.id) {
      addNodeChapter(
        chapterData.initTrigger.id,
        chapterData.initTrigger
      );
      addTriggerActions(chapterData.initTrigger && chapterData.initTrigger);
    }
  }

  function addTriggerActions(data: any) {
    if (data && data.triggerActions) {
      for (let i = 0; i < data.triggerActions.length; i++) {
        const actionKey = data.triggerActions[i];
        addActionNodeAndEdge(actionKey, data.id);
      }
    }
  }

  // TODO: not a specific function
  function addNodeChapter(nodeId: string, data: any) {

    const node = {
      ...position,
      chapterId: firstChapterId,
      id: nodeId,
      title: nodeId,
      type: NodeType.INIT_CHAPTER_NODE,
      data: data,
    };
    addNode(node);
  }

  // TODO: add edge type
  function addActionNodeAndEdge(actionKey: any, source: any) {
    let actionData = allActions[actionKey];
    if (actionData) {
      if (actionKey !== source) {
        //TODO: type
        // TODO: maybe Check if edges with required actions have been created
        addEdge(actionKey, source, "triggerActionEdge");
        //  Create the node and add it
        addActionNode(actionKey, actionData);

        addTriggerActions(actionData);

        // Add nodes for trigger actions
        addTriggersWithRequiredAction(actionKey);

        // TODO: not very clean
        if (actionKey.startsWith("endChapter") && actionData.nextChapterId) {
          // Get the chapter object
          const chapterData: any = chapters.items[actionData.nextChapterId];

          if (chapterData) {
            // Call addNodeChapterAndInitActions
            addNodeChapterAndInitActions(chapterData);

            // Add and edge between actionkey et id du nouveau chapitre
            addEdge(chapterData.initTrigger.id, actionKey, "newChapterEdge");
          }
        }
      } else {
        log.error(`Stackoverflow: action ${actionKey} having itself as triggered action`);
      }
    } else {
      log.error(`Action with id ${actionKey} not found in dictionary`);
    }
  }

  // TODO: actionKey and not action Data
  function addTriggersWithRequiredAction(actionKey: any) {
    // Look in the map of depending triggers with action key
    const dependingTriggers = allActionsAndDependingTriggers[actionKey];
    if (dependingTriggers) {
      // Loop on found triggers
      for (let i = 0; i < dependingTriggers.length; i++) {
        const triggerKey = dependingTriggers[i];
        const triggerData = allTriggers[triggerKey];

        addTriggerNode(triggerKey, triggerData);
        // Add the edge
        addEdge(triggerKey, actionKey, "requiredActionEdge");

        // Add actions triggered by the trigger
        addTriggerActions(triggerData);
      }
    }
  }

  /**
   * TODO: data BaseActionModel
   * @param id
   * @param data
   */
  function addActionNode(id: any, data: any) {
    const node = {
      ...position,
      id: id,
      title: id,
      type: NodeType.ACTION_NODE,
      data: data,
    };

    // Fill the title
    fillNodeTitles(node);

    addNode(node);
  }

  function addTriggerNode(id: any, data: BaseTriggerModel) {
    const node: NodeModel = {
      ...position,
      id: id,
      title: id,
      type: NodeType.TRIGGER_NODE,
      data: data,
    };

    fillNodeTitles(node);

    addNode(node);
  }

  function addNode(node: NodeModel) {
    if (node && !nodesMap.has(node.id)) {
      const typedNode = node as TypedNodeModel<BaseNodeData>;
      log.debug(`Adding ${typedNode.type} node (id: ${typedNode.id}) with ${typedNode.data.triggerActions?.length} triggered actions`);
      const nodeWithPosition = {
        ...node,
        ...position,
      };
      nodes.push(nodeWithPosition);
      nodesMap.set(node.id, nodeWithPosition);
    }
  }

  function addEdge(target: any, source: any, type: any) {
    const edgeId = `${source}_${target}`;
    if (!edgesMap.has(edgeId)) {
      const edge: EdgeModel = {
        source: source,
        target: target,
        type: type,
        id: edgeId,
      };
      edgesMap.set(edgeId, edge);
      edges.push(edge);
    }
  }

  for (let i = 0; i < nodes.length; i++) {
    nodesId.push(nodes[i].id);
  }
  for (let key in allActions) {
    if (!nodesId.includes(key)) {
      // unusedActions = [];
      unusedActions.push(allActions[key]);
    }
  }
  for (let key in allTriggers) {
    if (!nodesId.includes(key)) {
      // unusedTriggers = [];
      unusedTriggers.push(allTriggers[key]);
    }
  }

  function updateType(nodes: any[], id: any, key: any, value: any) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === id) {
        nodes[i][key] = value;
        return;
      }
    }
  }

  findDialogNode(nodes);

  /**
   * HACK: Fix action type for write dialog action
   * TODO: to be removed
   * @param nodes
   */
  function findDialogNode(nodes: NodeModel[]) {
    nodes.find((node: NodeModel) => {
      if (node.id && node.id.includes("triggers")) {
        return "";
      } else if (
        node.data &&
        node.data.type &&
        node.data.type.includes("writeDialog")
      ) {
        node.data.actionType = "DialogActionModel";
      }
      return "";
    });
    nodes.map((node) => {
      return getData(scenario, node.id, "characterId");
    });
  }

  function recursion(data: any, id: any) {
    for (let key in data) {
      if (data[key].id === id) {
        return data[key];
      } else if (typeof data[key] === "object") recursion(data[key], id);
    }
  }

  // TODO: to be removed
  async function getData(data: any, id: string, idType: string) {
    if (data) {
      for (let key in data) {
        const childData = data[key];
        if (childData) {
          if (childData.id === id && childData[idType]) {
            let send = recursion(scenario.characters, childData[idType]);
            let icon = `https://storage.googleapis.com/granaquestbackend.appspot.com/quests/${questKey}/${scenarioKey}/characters/${send.avatar}`;
            updateType(nodes, id, "icon", icon);
            updateType(nodes, id, "title", send.name.fr);
          } else if (typeof childData === "object")
            getData(childData, id, idType);
        }
      }
    }
  }
  let objNodes = {};
  let objEdges = {};

  for (let i = 0; i < nodes.length; i++) {
    objNodes = { ...objNodes, [nodes[i].id]: nodes[i] };
  }

  for (let i = 0; i < edges.length; i++) {
    objEdges = { ...objEdges, [edges[i].id]: edges[i] };
  }

  // Return nodeGraph
  return { objNodes, objEdges };
}

export function createNewNode(node: NodeModel, base: any) {
  let id;
  if (base.id) {
    id = base.id;
  } else {
    const identi = function () {
      return Math.random().toString(36).substr(2, 9);
    };
    id = `${base.actionType}_${identi()}`;
  }
  const nodeStructure = {
    id: id,
    type: base.type,
    data: {
      actionType: base.actionType,
      id: id,
      scenarioPath: `${base.scenarioPath}.${id}`,
    },
  };

  return nodeStructure;
}

export function getChapterNodesAndEdges(
  objNodes: { [key: string]: NodeModel },
  objEdges: { [key: string]: EdgeModel },
  chapterId: string
) {
  const selectedNodeAndEdges: GraphModel = {
    objNodes: {},
    objEdges: {},
  };

  // TODO: move in chapter service
  // Get chapter init node
  // TODO: ChapterInitTriggerModel when defined
  let chapterInitNode: TypedNodeModel<BaseTriggerModel> | null = null;
  for (let nodeId in objNodes) {
    const node = objNodes[nodeId];
    if (
      node.data.triggerType === "ChapterInitTriggerModel" &&
      node.data.chapterId === chapterId
    ) {
      chapterInitNode = node;
      break;
    }
  }

  if (chapterInitNode) {
    log.debug(`Found first chapter init trigger (id: ${chapterInitNode.data.id}) for chapter ${chapterId} with ${chapterInitNode.data.triggerActions?.length} triggered actions`);
    selectedNodeAndEdges.objNodes[chapterInitNode.id] = chapterInitNode;
    addNodeAndEdgesBeforeNextChapter(
      objNodes,
      objEdges,
      chapterInitNode.id,
      selectedNodeAndEdges
    );
  } else {
    log.error(`Chapter init node not foud for chapter ${chapterId}`);
  }

  return selectedNodeAndEdges;
}

export function addNodeAndEdgesBeforeNextChapter(
  objNodes: { [key: string]: NodeModel },
  objEdges: { [key: string]: EdgeModel },
  nodeId: any,
  selectedNodeAndEdges: any
) {
  const connectedNodeIds = [];

  // Loop on edges
  for (let edgeId in objEdges) {
    const edge = objEdges[edgeId];
    if (edge.source === nodeId) {
      // Add the edge
      console.log(`Adding edge: ${edge.id}`);
      selectedNodeAndEdges.objEdges[edge.id] = edge;
      // Add the connected node
      connectedNodeIds.push(edge.target);
    } else {
      //console.log(`Skipping edge: ${edge.id} (source: ${edge.source})`);
    }
  }

  connectedNodeIds.forEach((connectedNodeId) => {
    const connectedNode = objNodes[connectedNodeId];
    if (connectedNode.data.triggerType !== "ChapterInitTriggerModel") {
      selectedNodeAndEdges.objNodes[connectedNode.id] = connectedNode;
      addNodeAndEdgesBeforeNextChapter(
        objNodes,
        objEdges,
        connectedNodeId,
        selectedNodeAndEdges
      );
    }
  });
}
