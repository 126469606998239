import { all, put, takeEvery } from 'redux-saga/effects';
import actions from '../actions/actions';
import { rsf } from '@iso/lib/firebase/firebase';

import 'firebase/firestore';

function* addPicture(data) {
  const picture = data.payload.picture;
  const reference = data.payload.reference;
  console.log(picture);
  //creation of a title for the path of each instructions
  const storageFolderPath = `quests/${reference.questKey}/${reference.scenarioId}/instructions`;
  const storagePath = `${storageFolderPath}/${picture.name}`;
  console.log(storagePath);
  try {
    yield rsf.storage.uploadFile(storagePath, picture);
    yield put(
      actions.addInstructionSuccess({
        instruction: data.payload.instruction,
        picture: data.payload.picture,
      })
    );
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.ADD_INSTRUCTION, addPicture)]);
}
