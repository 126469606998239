import actions from '../actions/actions';

const initialState = {
  isLoading: false,
  errorMessage: false,
  users: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.LOAD_USERS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: payload.data,
      };
    case actions.LOAD_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.error,
      };

    default:
      return state;
  }
}
