import actions from "../actions/actions";
import charactersReducers from "../../Characters/reducers/reducers";
import objectsReducers from "../../Objects/reducers/reducers";
import chaptersReducers from "../../Chapters/reducers/reducers";
import instructionsReducers from "../../Instructions/reducers/reducer";
import arScenesReducers from "../../AR/reducers/reducers";
import SoundTracksReducers from "../../Soundtracks/reducers/reducers";
import VideosReducers from "../../Videos/reducers/reducers";
import HeritagesReducers from "../../Heritage/reducers/reducers";
import { createNodesAndEdges } from "../../AR/services/ARservice";
import _ from "lodash";
import { fillNodeTitles } from "../services/ScenarioService";
import ScenariosState from "../models/ScenariosState";
import NFCModel from "../../granaquest-library/nfc/models/NFCModel";
import NodeModel from "../models/NodeModel";
import EdgeModel, { EdgeType } from "../models/EdgeModel";
import Scenario from "../../granaquest-library/scenarios/models/Scenario";
import GameCharacter from "../../granaquest-library/characters/models/GameCharacter";
import GameObject from "../../granaquest-library/bag/models/GameObject";
import ChapterModel from "../../granaquest-library/chapters/models/ChapterModel";
import HeritageModel from "../../granaquest-library/heritage/models/HeritageModel";
import InstructionModel from "../../granaquest-library/instruction/models/InstructionModel";
import ARExperienceModel from "../../granaquest-library/ar/models/ARExperienceModel";
import GameSound from "../../granaquest-library/soundtracks/models/GameSound";
import { Reducer } from "react";

const initialState: ScenariosState = {
  isLoading: false,
  isAdded: false,
  notSelected: true,
  errorMessages: false,
  editorActive: false,
  actionModalActive: false,
  scenarios: {},
  selectedScenario: undefined,
  iconUrl: null,
  node: null,
  modal: {
    editor: false,
    saveButton: true,
    chooseActionMenu: false,
    action: "null",
  },
};

const scenariosReducers: Reducer<ScenariosState, any> = (
  state: ScenariosState = initialState,
  action: any
): ScenariosState => {
  const { type, payload } = action;
  if (state.selectedScenario && state.selectedScenario.videos) {
    const newVideosState = VideosReducers(state.selectedScenario.videos, {
      type,
      payload,
    });
    if (newVideosState !== state.selectedScenario.videos) {
      console.log("Videos state has changed");
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          videos: newVideosState,
        },
      };
    }
  }
  if (state.selectedScenario && state.selectedScenario.heritages) {
    const newHeritagesState = HeritagesReducers(
      state.selectedScenario.heritages,
      {
        type,
        payload,
      }
    );
    if (newHeritagesState !== state.selectedScenario.heritages) {
      console.log("Heritages State has changed");
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          heritages: newHeritagesState,
        },
      };
    }
  }

  if (state.selectedScenario && state.selectedScenario.soundTracks) {
    const newSoundTracksState = SoundTracksReducers(
      state.selectedScenario.soundTracks,
      { type, payload }
    );
    if (newSoundTracksState !== state.selectedScenario.soundTracks) {
      console.log("SoundTrack state has changed");
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          soundTracks: newSoundTracksState,
        },
      };
    }
  }

  if (state.selectedScenario && state.selectedScenario.characters) {
    const newCharactersState = charactersReducers(
      state.selectedScenario.characters,
      { type, payload }
    );

    if (newCharactersState !== state.selectedScenario.characters) {
      console.log("Characters state has changed");
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          characters: newCharactersState,
        },
      };
    }
  }

  if (state.selectedScenario && state.selectedScenario.aRExperiences) {
    const newArScenesState = arScenesReducers(
      state.selectedScenario.aRExperiences,
      {
        type,
        payload,
      }
    );

    if (newArScenesState !== state.selectedScenario.aRExperiences) {
      console.log("arScenes state has changed");
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          aRExperiences: newArScenesState,
        },
      };
    }
  }

  if (state.selectedScenario && state.selectedScenario.objects) {
    const newObjectsState = objectsReducers(state.selectedScenario.objects, {
      type,
      payload,
    });

    if (newObjectsState !== state.selectedScenario.objects) {
      console.log("Objects state has changed");
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          objects: newObjectsState,
        },
      };
    }
  }

  if (state.selectedScenario && state.selectedScenario.chapters) {
    const newChaptersState = chaptersReducers(state.selectedScenario.chapters, {
      type,
      payload,
    });

    if (newChaptersState !== state.selectedScenario.chapters) {
      console.log("Chapters state has changed");
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          chapters: newChaptersState,
        },
      };
    }
  }

  if (state.selectedScenario && state.selectedScenario.instructions) {
    const newInstructionsState = instructionsReducers(
      state.selectedScenario.instructions,
      {
        type,
        payload,
      }
    );
    if (newInstructionsState !== state.selectedScenario.instructions) {
      console.log("Instructions state has changed");

      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          instructions: newInstructionsState,
        },
      };
    }
  }

  switch (type) {
    case actions.ADD_NEW_ARSCENE:
      // TODO - refec : saga in AR to call function to add edges and nodes in scenarioGraph
      console.log(payload);
      const nodeSourceSelected = payload.node;
      const arExperienceId = payload.id;
      const arExperience =
        state.selectedScenario.aRExperiences.data[arExperienceId];
      const NodesAndEdges = createNodesAndEdges(
        nodeSourceSelected,
        arExperienceId,
        arExperience
      );
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          nodeGraph: {
            data: {
              objEdges: {
                ...state.selectedScenario.nodeGraph.data.objEdges,
                ...NodesAndEdges.edges,
              },
              objNodes: {
                ...state.selectedScenario.nodeGraph.data.objNodes,
                ...NodesAndEdges.nodes,
              },
            },
          },
        },
      };

    case actions.ADD_CHAPTER_ID:
      console.log(`added ${payload.nextChapter} in ${payload.chapterId}`);
      const chapterId = payload.chapterId;
      const nextChapter = payload.nextChapter;
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          chapters: {
            ...state.selectedScenario.chapters,
            data: {
              ...state.selectedScenario.chapters.data,
              items: {
                ...state.selectedScenario.chapters.data.items,
                [chapterId]: {
                  ...state.selectedScenario.chapters.data.items[chapterId],
                  nextChapterIds: [nextChapter],
                },
              },
            },
          },
        },
      };

    case actions.CHANGE_GAME:
      console.log(payload);
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          informations: {
            ...state.selectedScenario.informations,
            ...payload,
          },
        },
      };

    case actions.CREATE_NFC:
      const { nfcId, instruction } = payload;
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          nfcs: {
            ...state.selectedScenario.nfcs,
            data: {
              ...state.selectedScenario.nfcs.data,
              [nfcId]: {
                id: nfcId,
                forceQRCode: true,
                instruction: { fr: instruction },
              },
            },
          },
        },
      };
    case actions.DELETE_NFC_ID:
      console.log(`delete id : ${payload}`);
      let newNfc: { [key: string]: NFCModel } = {};
      const nfcsLibrary = state.selectedScenario.nfcs.data;
      for (let nfcsKey in nfcsLibrary) {
        if (nfcsKey !== payload) {
          newNfc[nfcsKey] = nfcsLibrary[nfcsKey];
        }
      }
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          nfcs: {
            data: newNfc,
          },
        },
      };

    case actions.CREATE_NEW_NODES_AND_EDGES:
      const newNodeCreated = payload;

      for (let nodeCreateId in newNodeCreated.objNodes) {
        const nodeCreate = newNodeCreated.objNodes[nodeCreateId];
        fillNodeTitles(nodeCreate);
      }

      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          nodeGraph: {
            ...state.selectedScenario.nodeGraph,
            data: {
              ...state.selectedScenario.nodeGraph.data,
              objEdges: {
                ...state.selectedScenario.nodeGraph.data.objEdges,
                ...newNodeCreated.objEdges,
              },
              objNodes: {
                ...state.selectedScenario.nodeGraph.data.objNodes,
                ...newNodeCreated.objNodes,
              },
            },
          },
        },
      };

    case actions.ADD_NEW_NODE:
      const { node } = payload.data;
      const newNode = payload.data.editedNode;
      const newEdge = {
        source: node.id,
        target: newNode.id,
        type: "triggerActionEdge",
      };

      let objNodes = {
        ...state.selectedScenario.nodeGraph.data.objNodes,
        [newNode.id]: newNode,
      };
      let objEdges = {
        ...state.selectedScenario.nodeGraph.data.objEdges,
        [newNode.id]: newEdge,
      };

      const data = { objNodes, objEdges };

      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          triggerEdit: {},
          nodeGraph: { data },
        },
      };

    case actions.EDIT_NODE:
      const { id, editedNode } = payload;
      //node modification by ID editeNode = old node + edited node
      return {
        ...state,

        modal: {
          ...state.modal,
          editor: false,
        },
        selectedScenario: {
          ...state.selectedScenario,
          triggerEdit: {},
          nodeGraph: {
            ...state.selectedScenario.nodeGraph,
            data: {
              ...state.selectedScenario.nodeGraph.data,
              objNodes: {
                ...state.selectedScenario.nodeGraph.data.objNodes,
                [id]: editedNode,
              },
            },
          },
        },
      };

    case actions.SAVE_SCENARIO:
      const edges: { [key: string]: EdgeModel } =
        payload.nodeGraph.data.objEdges;
      const nodes: { [key: string]: NodeModel } =
        payload.nodeGraph.data.objNodes;
      const scenar: Scenario = payload.selectedScenario.scenario;
      const characters: { [key: string]: GameCharacter } =
        payload.selectedScenario.characters.data;
      const objects: { [key: string]: GameObject } =
        payload.selectedScenario.objects.data;
      const chapters: { [key: string]: ChapterModel } =
        payload.selectedScenario.chapters.data;
      const heritages: { [key: string]: HeritageModel } =
        payload.selectedScenario.heritages.data;
      const instructions: { [key: string]: InstructionModel } =
        payload.selectedScenario.instructions.data;
      const aRExperiences: { [key: string]: ARExperienceModel } =
        payload.selectedScenario.aRExperiences.data;
      const soundTracks: { [key: string]: GameSound } =
        payload.selectedScenario.soundTracks.data;
      const nfcs: { [key: string]: NFCModel } = payload.selectedScenario.nfcs
        .data
        ? payload.selectedScenario.nfcs.data
        : {};

      const informations = payload.selectedScenario.informations;

      // Duplicate scenario object
      let newScenario: Scenario = {
        ...scenar,
      };

      // To delete all Actions and trigger
      // TODO: move in service
      const cleanTriggersAndActions = (data: any) => {
        for (let key in data) {
          const child = data[key];
          if (child) {
            if (child.triggerType || child.actionType) {
              //console.log(`Cleaning ${child.scenarioPath}`);
              delete data[key];
            } else {
              if (typeof child === "object") {
                cleanTriggersAndActions(child);
              }
            }
          }
        }
      };

      cleanTriggersAndActions(newScenario);

      // Add all elements here. (object, video, sounds and others...)
      newScenario = {
        ...newScenario,
        ...informations,
        characters: characters,
        objects: objects,
        chapters: chapters,
        heritage: heritages,
        instructions: instructions,
        aRExperiences: aRExperiences,
        nfcs: nfcs,
        sounds: soundTracks,
      };

      // Create a map of triggeredActions data scenario path is undefined specifik = not seter
      const triggeredActionsMap: { [key: string]: string[] } = {};
      const requiredActionsMap: { [key: string]: string[] } = {};
      for (let edgeKey in edges) {
        const edge = edges[edgeKey];
        if (edge.type === EdgeType.TRIGGER_ACTION_EDGE) {
          if (!triggeredActionsMap[edge.source]) {
            triggeredActionsMap[edge.source] = [];
          }
          const triggerActions = triggeredActionsMap[edge.source];
          if (!triggerActions.includes(edge.target)) {
            triggerActions.push(edge.target);
          }
        } else if (edge.type === EdgeType.REQUIRED_ACTION_EDGE) {
          if (!requiredActionsMap[edge.target]) {
            requiredActionsMap[edge.target] = [];
          }
          const requireActions = requiredActionsMap[edge.target];
          if (!requireActions.includes(edge.source)) {
            requireActions.push(edge.source);
          }
        } else {
          console.log("unanone : ", edge.type);
        }
      }

      // Set all actions and triggers using nodes.
      for (let nodeKey in nodes) {
        const node = nodes[nodeKey];
        const nodeData = node.data;
        const path = nodeData.scenarioPath;

        // Reset trigger actions
        if (path) {
          nodeData.triggerActions = triggeredActionsMap[nodeKey];
          if (!nodeData.triggerActions) {
            nodeData.triggerActions = [];
          }
          // Reset require actions for triggers
          if (nodeData.triggerType) {
            nodeData.requireActions = requiredActionsMap[nodeKey];
            if (!nodeData.requireActions) {
              nodeData.requireActions = [];
            }
          }

          _.set(newScenario, path, nodeData);
        } else {
          console.log("pas de path definie");
        }
      }
      //console.log(requiredActionsMap);
      //console.log(triggeredActionsMap);
      console.log("here :");
      console.log(newScenario);
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          isSaving: true,
          newScenario: newScenario,
        },
      };

    case actions.OPEN_EDITOR:
      return {
        ...state,
        modal: {
          ...state.modal,
          editor: payload,
        },
      };

    case actions.UPDATE_SCENARIO:
      return {
        ...state,
        isLoading: true,
      };

    case actions.SAVE_SCENARIO_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.OPEN_CREATOR:
      return {
        ...state,
        modal: {
          action: "null",
          chooseActionMenu: payload,
        },
      };

    case actions.CHANGE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          action: payload.action,
        },
      };
    case actions.CLOSE_ALL_MODAL:
      return {
        ...state,
        modal: {
          chooseActionMenu: false,
          action: "null",
        },
      };
    case actions.LOAD_SCENARIOS:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.MODAL_SELECTOR:
      return {
        ...state,
        notSelected: payload.data,
      };
    case actions.LOAD_SCENARIOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scenarios: payload.data,
        errorMessages: false,
      };
    case actions.LOAD_SCENARIO:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.LOAD_SCENARIO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedScenario: payload.data,
        errorMessages: false,
      };
    case actions.RESET_SCENARIO:
      return {
        ...state,
        selectedScenario: initialState.selectedScenario,
      };
    case actions.TOGGLE_MODAL_EDITOR:
      return {
        ...state,
        editorActive: !state.editorActive,
        node: payload.data === null ? initialState.node : payload.data,
      };
    case actions.TOGGLE_MODAL_ACTION:
      return {
        ...state,
        actionModalActive: !state.actionModalActive,
        node: payload.data === null ? initialState.node : payload.data,
      };
    case actions.GET_URL_ICON:
      return {
        ...state,
        iconUrl: payload.data,
      };
    case actions.INPUT_NODE:
      return {
        ...state,
        node: payload.data,
      };
    case actions.UPDATE_NODE:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.UPDATE_NODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        node: payload.data,
        errorMessages: false,
      };
    case actions.LOAD_SCENARIO_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessages: payload.error,
      };
    case actions.CLOSE_IS_SAVING:
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          isSaving: false,
        },
      };
    case actions.SAVE_SCENARIO_DRAFT:
      console.log("saving draft");
      return {
        ...state,
        isLoading: true,
        selectedScenario: {
          ...state.selectedScenario,
          isSaving: false,
        },
      };
    case actions.UPDATE_SCENARIO_SUCCESS:
      console.log(payload);
      // TODO: nothing is done ?
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          drafts: {
            ...state.selectedScenario.drafts,
            //  [payload.version.name]: payload,
          },
        },
        isLoading: false,
      };
    case actions.EDIT_TRIGGER:
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          triggerEdit: {
            ...state.selectedScenario.triggerEdit,
            allowed: payload.allowedAction,
            unlock: payload.unlock,
            edit: true,
            source: payload.source,
          },
        },
      };
    case actions.ADD_MODIFY_UNLOCK_TRIGGER:
      const { object, actionsAllowed, allowedActionsData } = payload;
      console.log(payload);

      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          objects: {
            ...state.selectedScenario.objects,
            data: {
              ...state.selectedScenario.objects.data,
              [object]: {
                ...state.selectedScenario.objects.data[object],
                triggers: {
                  ...state.selectedScenario.objects.data[object].triggers,
                  ...actionsAllowed,
                },
                allowedActions: {
                  ...state.selectedScenario.objects.data[object].allowedActions,
                  ...allowedActionsData,
                  give: {},
                },
              },
            },
          },
        },
      };

    case actions.DELETE_UNLOCK_TRIGGER:
      const { objectId, actionRef } = payload;
      const newObject = state.selectedScenario.objects.data[objectId];
      // recentrer les valeurs sur l'objet uniquement.
      // rendre cette fonction plus general delete unlock/playsound/combine
      if (newObject.allowedActions) {
        if (newObject.allowedActions[actionRef]) {
          delete newObject.allowedActions[actionRef];
          delete newObject.triggers[actionRef];
        }
      }

      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          objects: {
            ...state.selectedScenario.objects,
            data: {
              ...state.selectedScenario.objects.data,
              [objectId]: newObject,
            },
          },
        },
      };

    case actions.DELETE_DRAFT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_DRAFT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedScenario: {
          ...state.selectedScenario,
          drafts: payload,
        },
      };

    case actions.DELETE_NODE:
      const { reconnectMode, nodeToDeleteId } = payload;
      const nodesMap = { ...state.selectedScenario.nodeGraph.data.objNodes };
      let edgesMap = { ...state.selectedScenario.nodeGraph.data.objEdges };

      // Add the node to be deleted to the list of node to delete
      const nodeIdsToDelete = [nodeToDeleteId];
      const edgeIdsTodelete = [];

      const reconnectNodes = [];
      let reconnectSource = "";

      for (let edgeId in edgesMap) {
        const edge = edgesMap[edgeId];
        //First remove all edges related to this node

        if (edge.target === nodeToDeleteId && !reconnectNodes) {
          edgeIdsTodelete.push(edgeId);
        }

        if (edge.source === nodeToDeleteId) {
          edgeIdsTodelete.push(edgeId);
        }

        // Find the source node if reconnect is activated
        if (
          reconnectMode &&
          edge.target === nodeToDeleteId &&
          edge.type === "triggerActionEdge"
        ) {
          reconnectSource = edge.source;
        }

        // If the source is the deleted  node, add to reconnect node list in order to reconnect
        if (edge.source === nodeToDeleteId) {
          const idNodeTarget = edge.target;
          const targetNode = nodesMap[idNodeTarget];
          // If it's a trigger node, delete it too & reconnect the triggered actions
          if (targetNode.type === "triggerNode") {
            nodeIdsToDelete.push(idNodeTarget);
            // Find the triggered actions and ad it to the list of reconnected
            if (reconnectMode) {
              for (let triggerEdgeId in edgesMap) {
                const triggerEdge = edgesMap[triggerEdgeId];
                if (triggerEdge.source === idNodeTarget) {
                  edgeIdsTodelete.push(triggerEdge.id);
                  const triggerNode = nodesMap[triggerEdge.target];
                  reconnectNodes.push(triggerNode);
                }
              }
            }
            // Not a trigger, add it to the list of nodes to be reconnected
          } else if (reconnectMode) {
            reconnectNodes.push(targetNode);
          }
        }
      }

      //TODO Si un noeud est tout seul mettre un warning visible pour le raccorder.

      for (let i in reconnectNodes) {
        const edgeTosource = {
          id: `${reconnectSource}_to_${reconnectNodes[i].id}`,
          source: reconnectSource,
          target: reconnectNodes[i].id,
          type: "triggerActionEdge",
        };
        console.log(edgeTosource);
        edgesMap = {
          ...edgesMap,
          [edgeTosource.id]: edgeTosource,
        };
      }
      // TODO: loop on reconnect node, and recreate adges
      //const newId = `${newEdgeCreat.source}_${newEdgeCreat.target}`;
      //newEdgeCreat.type = "triggerActionEdge";
      //newEdgeCreat.id = newId;

      // Delete all needed nodes and edges
      for (let iEdge in edgeIdsTodelete) {
        const edgeId = edgeIdsTodelete[iEdge];
        console.log(`Delete edge: ${edgeId}`);
        delete edgesMap[edgeId];
      }
      for (let iNode in nodeIdsToDelete) {
        const nodeId = nodeIdsToDelete[iNode];
        console.log(`Delete node: ${nodeId}`);
        delete nodesMap[nodeId];
      }

      /*
      let newObjEdges = { ...edgesArray };
      if (newEdgeCreat.target) {
        newObjEdges[newId] = { ...newEdgeCreat };
      }*/

      return {
        ...state,
        node: null,
        selectedScenario: {
          ...state.selectedScenario,
          nodeGraph: {
            ...state.selectedScenario.nodeGraph,
            data: {
              ...state.selectedScenario.nodeGraph.data,
              objEdges: edgesMap,
              objNodes: nodesMap,
            },
          },
        },
      };

    case actions.ACTIVE_GIVE_OBJECT:
      console.log(payload);
      let newDataObjects = state.selectedScenario.objects.data;

      const { giveObject } = payload;
      console.log(giveObject);
      for (let objectId in giveObject.objectIds) {
        newDataObjects[objectId] = {
          ...newDataObjects[objectId],
          allowedActions: {
            ...newDataObjects[objectId].allowedAction,
            give: {},
          },
        };
      }

      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          objects: {
            ...state.selectedScenario.objects,
            data: newDataObjects,
          },
        },
      };

    case actions.CHANGE_PICTURE_THEME:
      return {
        ...state,
        isLoading: true,
      };

    case actions.CHANGE_PICTURE_THEME_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.CREATE_NEW_SCENARIO:
      return {
        ...state,
        isLoading: true,
        isAdded: true,
      };

    case actions.CHANGE_OBJECT:
      console.log(payload);

      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          objects: {
            ...state.selectedScenario.objects,
            data: {
              ...state.selectedScenario.objects.data,
              [payload.id]: payload,
            },
          },
        },
      };

    case actions.CREATE_NEW_SCENARIO_SUCCESS:
      console.log("success");
      return {
        ...state,
        isLoading: false,
        isAdded: false,
      };
    case actions.UPDATE_HINTS:
      const { hints, hintId } = payload;
      let hintsData = [];
      for (let hint in hints) {
        hintsData.push(hints[hint]);
      }
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          nodeGraph: {
            ...state.selectedScenario.nodeGraph,
            data: {
              ...state.selectedScenario.nodeGraph.data,
              objNodes: {
                ...state.selectedScenario.nodeGraph.data.objNodes,
                [hintId]: {
                  ...state.selectedScenario.nodeGraph.data.objNodes[hintId],
                  data: {
                    ...state.selectedScenario.nodeGraph.data.objNodes[hintId]
                      .data,
                    textHints: hintsData,
                  },
                },
              },
            },
          },
        },
      };

    case actions.CREATE_EDGE:
      console.log(payload);
      const { source, target } = payload;
      return {
        ...state,
        selectedScenario: {
          ...state.selectedScenario,
          nodeGraph: {
            ...state.selectedScenario.nodeGraph,
            data: {
              ...state.selectedScenario.nodeGraph.data,
              objEdges: {
                ...state.selectedScenario.nodeGraph.data.objEdges,
                [`${source}_to_${target}`]: {
                  source: source,
                  target: target,
                  id: `${source}_to_${target}`,
                  type: "triggerActionEdge",
                },
              },
            },
          },
        },
      };

    default:
      return state;
  }
};

export default scenariosReducers;
