import EndChapterActionModel from "../../granaquest-library/chapters/models/actions/EndChapterActionModel";
import ActionType from "../../granaquest-library/games/models/actions/ActionType";
import Scenario from "../../granaquest-library/scenarios/models/Scenario";
import TriggerType from "../../granaquest-library/triggers/models/triggers/TriggerType";
import EdgeModel, { EdgeType } from "../../Scenarios/models/EdgeModel";
import NodeModel, {
  NodeType,
  TypedNodeModel,
} from "../../Scenarios/models/NodeModel";
import AGameSubManager from "../../Scenarios/services/AGameSubManager";

// Get all triggers from scenario
export function getChaptersState(
  questKey: any,
  scenarioId: any,
  scenario: any
) {
  let chapters = {};
  if (scenario.chapters) {
    chapters = scenario.chapters;
  }

  const chaptersState = {
    data: chapters,
    object: null,
    modalActive: false,
    isLoading: false,
  };

  return chaptersState;
}
// Create Node Chapter function.
export function nodeEndChapterMaker(payload: any) {
  const endingChapterId = payload.endingChapterId;
  const { creation, nodeToDeleteId } = payload;
  const nodeSource = payload.node;
  let objEdges = {};
  let objNodes = {};

  const endChapter: TypedNodeModel<EndChapterActionModel> = {
    id: creation ? `endChapter-${endingChapterId}` : nodeSource.id,
    type: NodeType.ACTION_NODE,
    data: {
      chapterId: endingChapterId,
      nextChapterId: payload.endGame ? payload.endGame : undefined,
      id: creation ? `endChapter-${endingChapterId}` : nodeSource.id,
      scenarioPath: creation
        ? `chapters.items.${endingChapterId}.actions.endChapters.endChapter-${endingChapterId}`
        : nodeSource.data.scenarioPath,
      actionType: ActionType.EndChapterActionModel,
    },
  };

  let edgeSourceToEndChapter: EdgeModel = {
    source: nodeSource.id,
    target: endChapter.id,
    type: EdgeType.TRIGGER_ACTION_EDGE,
    id: `${nodeSource.id}-edges`,
  };
  objNodes = {
    ...objNodes,
    [endChapter.id]: endChapter,
  };
  if (creation) {
    objEdges = {
      ...objEdges,
      [edgeSourceToEndChapter.id]: edgeSourceToEndChapter,
    };
  }
  //TODO change endGame
  if (payload.endGame) {
    //If not end game create new chapter trigger
    // Before delete  oldNewChapter and OldedgeToneChapter.

    const newChapter = {
      id: creation ? `StartChapter_${payload.endGame}` : nodeToDeleteId,
      type: NodeType.INIT_CHAPTER_NODE,
      data: {
        id: creation ? `StartChapter_${payload.endGame}` : nodeToDeleteId,
        chapterId: payload.endGame,
        triggerType: "ChapterInitTriggerModel",
        scenarioPath: `chapters.items.${payload.endGame}.initTrigger`,
      },
    };

    const edgeToNewChapter: EdgeModel = {
      id: `${endChapter.id}_to_${newChapter.id}`,
      type: EdgeType.TRIGGER_ACTION_EDGE,
      source: endChapter.id,
      target: newChapter.id,
    };

    objNodes = {
      ...objNodes,
      [newChapter.id]: newChapter,
    };
    objEdges = {
      ...objEdges,
      [edgeToNewChapter.id]: edgeToNewChapter,
    };
  }

  return {
    objNodes,
    objEdges,
  };
}

class ChapterService extends AGameSubManager {
  /**
   * Consolidate character datas by
   * @param {*} scenario
   */
  consolidateData(scenario: Scenario): void { }


  /**
   * Get the action path in the scenario
   * @param action
   */
  getActionScenarioPath(action: any): string | undefined {
    // TODO: To be implemented
    return
  }

  findPreviousChapterInit(
    nodeId: string,
    nodes: { [key: string]: NodeModel },
    edges: { [key: string]: EdgeModel }
  ): TypedNodeModel<any> | null {
    for (let edgeId in edges) {
      const edge = edges[edgeId];
      if (edge.target === nodeId) {
        const sourceNode = nodes[edge.source];
        if (sourceNode) {
          if (
            sourceNode.data.triggerType === TriggerType.ChapterInitTriggerModel
          ) {
            return sourceNode;
          } else {
            const foundNode = this.findPreviousChapterInit(
              sourceNode.id,
              nodes,
              edges
            );
            if (foundNode) {
              return foundNode;
            }
          }
        }
      }
    }

    return null;
  }

  /**
   * Get all chapter actions and fill the given map with it
   * @param scenario The scenario
   * @param allActionsMap The action map
   */
  getActions(scenario: Scenario, allActionsMap: { [key: string]: any }): void { }

  /**
   * get all chapter triggers
   * @param {*} scenario
   */
  getTriggers(scenario: Scenario): void { }
}

const chapterService = new ChapterService();
export default chapterService;
