import actions from '../actions/actions';

const initialState = {
  isLoading: false,
  errorMessages: false,
  heritage: {
    description: {
      fr: '',
    },
    imagesCopyrights: '',
    textSources: '',
    title: {
      fr: '',
    },
  },
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.LOAD_HERITAGES:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.LOAD_HERITAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        heritages: payload.data,
        errorMessages: false,
      };
    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessages: payload.error,
      };
    case actions.TOGGLE_MODAL_HERITAGE:
      return {
        ...state,
        modalActive: !state.modalActive,
        heritage: payload.data == null ? initialState.heritage : payload.data,
      };
    case actions.INPUT_HERITAGE:
      return {
        ...state,
        heritage: payload.data,
      };
    case actions.CREATE_HERITAGE:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.CREATE_HERITAGE_SUCCESS:
      const newHeritage = payload.data.newHeritage;

      return {
        ...state,
        data: {
          ...state.data,
          [newHeritage.id]: {
            ...newHeritage,
          },
        },
        test: true,
        isLoading: false,
        heritages: payload.heritageData,
        errorMessages: false,
      };
    case actions.UPDATE_HERITAGE:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.UPDATE_HERITAGE_SUCCESS:
      console.log(payload);
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [payload.data.id]: {
            ...payload.data,
          },
        },
        errorMessages: false,
      };
    case actions.DELETE_HERITAGE:
      const newData = state.data;
      delete newData[payload.heritageData.id];
      return {
        ...state,
        data: newData,
        isLoading: true,
        errorMessages: false,
      };
    default:
      return state;
  }
}
