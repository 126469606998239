import { all, put, takeEvery } from 'redux-saga/effects';
import actions from '../actions/actions';
import { rsf } from '@iso/lib/firebase/firebase';
//import { documentRef, createJson, storageRef } from '../../common/functions';
//import * as firebase from 'firebase/app';
import 'firebase/firestore';

/*
function* loadVideos(data) {
  const { reference } = data.payload;
  try {
    const snapshot = yield call(rsf.firestore.getDocument, documentRef(data));

    let scenario = snapshot.data();
    let videos = [];
    let videosSourceUrl = [];
    yield all(
      Object.keys(scenario)
        .filter(field => field === 'videos')
        .map(field => {
          return videos.push(
            (videos = {
              ...scenario[field],
            })
          );
        })
    );
    yield all(
      Object.values(videos).map(function*(video) {
        if (video.source) {
          const url = yield call(
            rsf.storage.getDownloadURL,
            `quests/${reference.questKey}/${reference.scenarioId}/videos/${video.source}`
          );
          videosSourceUrl.push(
            (video = {
              ...video,
              videoUrl: url,
            })
          );
        }
      })
    );
    yield put(actions.loadVideosSuccess(videosSourceUrl));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}
*/

function* createVideo(data) {
  const { reference, videoState } = data.payload;
  if (videoState !== null) {
    const task = rsf.storage.uploadFile(
      `quests/${reference.questKey}/${reference.scenarioId}/videos/${videoState.name}`,
      videoState
    );
    try {
      yield task;
      yield put(actions.createVideosSuccess(data));
      //  yield call(createJson, data);
    } catch (error) {
      console.log(error);
      yield put(actions.loadError(error));
    }
  }
}
/*
function* updateVideo(data) {
  const { videoState, videoData, reference } = data.payload;
  const clone = Object.assign({}, videoData);
  delete clone.videoUrl;
  delete clone.key;
  try {
    const task = rsf.storage.uploadFile(
      `quests/${reference.questKey}/${reference.scenarioId}/videos/${videoData.source}`,
      videoState
    );
    yield task;
    yield call(
      rsf.firestore.setDocument,
      `quests/${reference.questKey}/scenarios/${reference.scenarioId}`,
      {
        videos: {
          [clone.id]: {
            ...clone,
          },
        },
      },
      { merge: true }
    );
    yield put(actions.updateVideoSuccess(videoData));
  //  yield call(loadVideos, data);
    yield call(createJson, data);
  } catch (error) {
    yield put(actions.loadError(error));
  }
}
*/
function* deleteVideo(data) {
  // const { videoData } = data.payload;
  try {
    /*
    yield storageRef(data, 'videos', videoData.source)
      .delete()
      .then(() => console.log('File deleted successfully!'))
      .catch(error => console.log(error));
*/
    yield put(actions.deleteVideoSuccess(data));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

export default function* rootSaga() {
  yield all([
    //  takeEvery(actions.LOAD_VIDEOS, loadVideos),
    takeEvery(actions.CREATE_VIDEO, createVideo),
    // takeEvery(actions.UPDATE_VIDEO, updateVideo),
    takeEvery(actions.DELETE_VIDEO, deleteVideo),
  ]);
}
