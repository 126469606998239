import actions from '../actions/actions';

const initialState = {
  isLoading: false,
  errorMessages: false,
  modalActive: false,
  aRSaRExperiencescenes: {},
  aRScene: {
    instruction: {
      fr: '',
    },
  },
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.LOAD_AR:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.LOAD_AR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        aRExperiences: payload.data,
        errorMessages: false,
      };
    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessages: payload.error,
      };
    case actions.TOGGLE_MODAL_AR:
      return {
        ...state,
        modalActive: !state.modalActive,
        aRScene: payload.data == null ? initialState.aRScene : payload.data,
      };
    case actions.INPUT_AR:
      return {
        ...state,
        aRScene: payload.data,
      };
    case actions.CREATE_AR:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.CREATE_AR_SUCCESS:
      console.log(payload);

      const { aRData, id, files, augmentation } = payload;

      let newFiles = [];

      if (aRData.files?.lenght > 0) {
        newFiles = aRData.files;
      } else {
        newFiles = files;
      }

      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [id]: { ...aRData, id: id, files: newFiles, ...augmentation },
        },
        /*
        aRScenes: {
          ...state.aRScenes,
          data: {
            ...state.aRScenes.data,
            [sceneId]: {
              id: sceneId,
              files: files,
              ...aRData,
            },
          },
        },
      },*/
      };
    case actions.UPDATE_AR:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.DELETE_AR:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.DELETE_AR_SUCCESS:
      const newData = state.data;
      delete newData[payload.id];

      return {
        ...state,
        isLoading: false,
        data: {
          ...newData,
        },
      };
    default:
      return state;
  }
}
