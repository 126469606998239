import actions from '../actions/actions';
import { UserProfileState } from '../models/UserProfileState';

/**
 * 
 */
const initState: UserProfileState = {
  isLoading: false,
  errorMessage: undefined,
  profile: undefined,
};

export default function reducer(state = initState, { type, payload, error }: any): UserProfileState {
  switch (type) {
    case actions.LOAD_USER_PROFILE:
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined,
      };
    case actions.LOAD_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profile: payload.data,
        errorMessage: undefined,
      };
    case actions.LOAD_USER_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.error,
      };

    default:
      return state;
  }
}
