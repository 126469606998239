import QuestModel from "../../granaquest-library/quests/models/QuestModel";

/**
 * TODO: Generic load error
 */
export interface LoadErrorAction {
  type: typeof actions.LOAD_ERROR;
  payload: {
    error: any;
  }
}

export interface LoadSingleQuestAction {
  type: typeof actions.LOAD_SINGLE_QUEST;
  payload: {
    questKey: string;
  }
}

export interface LoadSingleQuestSuccessAction {
  type: typeof actions.LOAD_SINGLE_QUEST_SUCCESS;
  payload: {
    quest: QuestModel;
  }
}

export interface UpdateInputAction {
  type: typeof actions.UPDATE_INPUT;
  payload: {
    quest: QuestModel;
  }
}

export interface UpdateQuestAction {
  type: typeof actions.UPDATE_QUEST;
  payload: {
    quest: QuestModel;
  }
}

export interface UpdateQuestSuccessAction {
  type: typeof actions.UPDATE_QUEST_SUCCESS;
  payload: {
    quest: QuestModel;
  }
}

export interface UpdateQuestPictureAction {
  type: typeof actions.UPDATE_QUEST_PICTURE;
  payload: {
    // Picture data is Blob, File or Uint8Array
    data: Blob | File | Uint8Array;
  }
}

export type QuestActionTypes =
  LoadErrorAction
  | LoadSingleQuestAction
  | LoadSingleQuestSuccessAction
  | UpdateInputAction
  | UpdateQuestAction
  | UpdateQuestSuccessAction
  | UpdateQuestPictureAction;

const actions = {
  LOAD_ERROR: 'LOAD_ERROR',
  LOAD_SINGLE_QUEST: 'LOAD_SINGLE_QUEST',
  LOAD_SINGLE_QUEST_SUCCESS: 'LOAD_SINGLE_QUEST_SUCCESS',
  UPDATE_INPUT: 'UPDATE_INPUT',
  UPDATE_QUEST: 'UPDATE_QUEST',
  UPDATE_QUEST_SUCCESS: 'UPDATE_QUEST_SUCCESS',
  UPDATE_QUEST_PICTURE: 'UPDATE_QUEST_PICTURE',

  loadError: (error: any): LoadErrorAction => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  loadSingleQuest: (questKey: string): LoadSingleQuestAction => {
    return {
      type: actions.LOAD_SINGLE_QUEST,
      payload: { questKey },
    }
  },

  loadSingleQuestSuccess: (quest: QuestModel): LoadSingleQuestSuccessAction => ({
    type: actions.LOAD_SINGLE_QUEST_SUCCESS,
    payload: { quest },
  }),

  /**
   * Update input only update in state but does not save in firestore
   */
  updateInput: (quest: QuestModel): UpdateInputAction => ({
    type: actions.UPDATE_INPUT,
    payload: { quest },
  }),

  /**
   * Save quest in firestore
   */
  updateQuest: (quest: QuestModel): UpdateQuestAction => ({
    type: actions.UPDATE_QUEST,
    payload: { quest },
  }),

  updateQuestSuccess: (quest: QuestModel): UpdateQuestSuccessAction => ({
    type: actions.UPDATE_QUEST_SUCCESS,
    payload: { quest },
  }),

  updateQuestPicture: (data: Blob | File | Uint8Array): UpdateQuestPictureAction => ({
    type: actions.UPDATE_QUEST_PICTURE,
    payload: { data },
  }),

};

export default actions;
