import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import ecommerceSaga from '@iso/redux/ecommerce/saga';
import questsSaga from '../QuestsDashboard/sagas/sagas';
import questSaga from '../Quest/sagas/sagas';
import scenariosSaga from '../Scenarios/sagas/sagas';
import charactersSaga from '../Characters/sagas/sagas';
import soundtracksSaga from '../Soundtracks/sagas/sagas';
import videosSaga from '../Videos/sagas/sagas';
import objectsSaga from '../Objects/sagas/sagas';
import heritagesSaga from '../Heritage/sagas/sagas';
import aRSaga from '../AR/sagas/sagas';
import gamesSaga from '../Games/sagas/sagas';
import usersSaga from '../Users/sagas/sagas';
import profileSaga from '../authentication/sagas/profileSagas';
import chaptersSaga from '../Chapters/sagas/sagas';
import instructionSagas from '../Instructions/sagas/sagas';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    ecommerceSaga(),
    questsSaga(),
    questSaga(),
    scenariosSaga(),
    charactersSaga(),
    soundtracksSaga(),
    videosSaga(),
    objectsSaga(),
    heritagesSaga(),
    aRSaga(),
    gamesSaga(),
    usersSaga(),
    profileSaga(),
    chaptersSaga(),
    instructionSagas(),
  ]);
}
