const LOAD_USER_PROFILE = 'LOAD_USER_PROFILE';
const actions = {
  LOAD_USER_PROFILE: LOAD_USER_PROFILE,
  LOAD_USER_PROFILE_SUCCESS: LOAD_USER_PROFILE + '_SUCCESS',
  LOAD_USER_PROFILE_ERROR: LOAD_USER_PROFILE + '_ERROR',

  loadUserProfile: data => ({
    type: actions.LOAD_USER_PROFILE,
    payload: { data },
  }),

  loadUserProfileSuccess: data => ({
    type: actions.LOAD_USER_PROFILE_SUCCESS,
    payload: { data },
  }),

  loadUserProfileError: error => ({
    type: actions.LOAD_USER_PROFILE_ERROR,
    payload: { error },
  }),
};

export default actions;
