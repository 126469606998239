const actions = {
  LOAD_VIDEOS: 'LOAD_VIDEOS',
  LOAD_VIDEOS_SUCCESS: 'LOAD_VIDEOS_SUCCESS',
  LOAD_ERROR: 'LOAD_ERROR',
  TOGGLE_MODAL_VIDEO: 'TOGGLE_MODAL_VIDEO',
  CREATE_VIDEO: 'CREATE_VIDEO',
  UPDATE_VIDEO: 'UPDATE_VIDEO',
  UPDATE_VIDEO_SUCCESS: 'UPDATE_VIDEO_SUCCESS',
  DELETE_VIDEO: 'DELETE_VIDEO',
  CREATE_VIDEO_SUCCESS: 'CREATE_VIDEO_SUCCESS',
  DELETE_VIDEO_SUCCESS: 'DELETE_VIDEO_SUCCESS',

  loadVideos: reference => ({
    type: actions.LOAD_VIDEOS,
    payload: { reference },
  }),

  loadVideosSuccess: data => ({
    type: actions.LOAD_VIDEOS_SUCCESS,
    payload: { data },
  }),

  loadError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  toggleModalVideo: (data = null) => ({
    type: actions.TOGGLE_MODAL_VIDEO,
    payload: { data },
  }),

  createVideo: (videoState, reference) => ({
    type: actions.CREATE_VIDEO,
    payload: { videoState, reference },
  }),
  createVideosSuccess: data => ({
    type: actions.CREATE_VIDEO_SUCCESS,
    payload: data,
  }),
  updateVideo: (videoState, videoData, reference) => ({
    type: actions.UPDATE_VIDEO,
    payload: { videoState, videoData, reference },
  }),

  updateVideoSuccess: data => ({
    type: actions.UPDATE_VIDEO_SUCCESS,
    payload: { data },
  }),

  deleteVideo: (videoData, reference) => ({
    type: actions.DELETE_VIDEO,
    payload: { videoData, reference },
  }),
  deleteVideoSuccess: data => ({
    type: actions.DELETE_VIDEO_SUCCESS,
    payload: data,
  }),
};

export default actions;
