// Get all triggers from scenario
export function getSoundTracksState(questKey, scenarioId, scenario) {
  let soundTracks = {};
  if (scenario.chapters) {
    soundTracks = scenario.sounds;
  }

  for (const soundTracksId in soundTracks) {
    const soundTrack = soundTracks[soundTracksId];
    const { source } = soundTrack;
    soundTracks[
      soundTracksId
    ].soundtrackUrl = `https://storage.googleapis.com/granaquestbackend.appspot.com/quests/${questKey}/${scenarioId}/soundtracks/${source}`;
  }
  const soundTracksState = {
    data: soundTracks,
    object: null,
    modalActive: false,
    isLoading: false,
  };

  return soundTracksState;
}
