import { all, put, takeEvery } from 'redux-saga/effects';
import actions from '../actions/actions';
import { rsf } from '@iso/lib/firebase/firebase';
import 'firebase/firestore';

/*
function* loadSoundtracks(data) {
  const { reference } = data.payload;
  try {
    const snapshot = yield call(rsf.firestore.getDocument, documentRef(data));

    let scenario = snapshot.data();
    let sounds = [];
    let soundSourceUrl = [];
    yield all(
      Object.keys(scenario)
        .filter(field => field === 'sounds')
        .map(field => {
          return sounds.push(
            (sounds = {
              ...scenario[field],
            })
          );
        })
    );
    yield all(
      Object.values(sounds).map(function*(sound) {
        if (sound.source) {
          const url = yield call(
            rsf.storage.getDownloadURL,
            `quests/${reference.questKey}/${reference.scenarioId}/soundtracks/${sound.source}`
          );
          soundSourceUrl.push(
            (sound = {
              ...sound,
              soundtrackUrl: url,
            })
          );
        }
      })
    );
    yield put(actions.loadSoundtracksSuccess(soundSourceUrl));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}
*/
function* createSoundtrack(data) {
  const { reference, soundtrackState } = data.payload;
  if (soundtrackState !== null) {
    const task = rsf.storage.uploadFile(
      `quests/${reference.questKey}/${reference.scenarioId}/soundtracks/${soundtrackState.name}`,
      soundtrackState
    );
    try {
      yield task;
      yield put(actions.createSoundtrackSuccess(data));
    } catch (error) {
      yield put(actions.loadError(error));
    }
  }
}

function* updateSoundtrack(data) {
  const { soundtrackState, soundtrackData, reference } = data.payload;
  const clone = Object.assign({}, soundtrackData);
  delete clone.soundtrackUrl;
  delete clone.key;
  try {
    const task = rsf.storage.uploadFile(
      `quests/${reference.questKey}/${reference.scenarioId}/soundtracks/${soundtrackData.source}`,
      soundtrackState
    );
    yield task;
    yield put(actions.updateSoundtrackSuccess(soundtrackData));
    // yield call(loadSoundtracks, data);
    yield put(actions.updateSoundtrackSuccess(data));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

function* deleteSoundtrack(data) {
  // const { soundtrackData } = data.payload;
  try {
    /*
    yield storageRef(data, 'soundtracks', soundtrackData.source)
      .delete()
      .then(() => {
        console.log('File deleted successfully!');
      })
      .catch(error => console.log(error));
*/
    yield put(actions.deleteSoundtrackSuccess(data));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.LOAD_SOUNDTRACKS, loadSoundtracks),
    takeEvery(actions.CREATE_SOUNDTRACK, createSoundtrack),
    takeEvery(actions.UPDATE_SOUNDTRACK, updateSoundtrack),
    takeEvery(actions.DELETE_SOUNDTRACK, deleteSoundtrack),
  ]);
}
