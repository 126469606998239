import { all, put, takeEvery, call } from "redux-saga/effects";
import actions from "../actions/actions";
import { rsf, db } from "@iso/lib/firebase/firebase";
import { getStoragePublicPath } from "@iso/lib/firebase/firebase.util";
import firebase from "firebase/app";
import { convertCollectionsSnapshotToMap } from "@iso/lib/firebase/firebase.util";
import scenarioService from "../../Scenarios/services/ScenarioService";
/*
function* listAll(pathOrRef) {
  const ref = rsf.app.storage().ref(pathOrRef);
  console.log(ref);
  //const ref = getRef(rsf.app.storage(), pathOrRef)
  const files = yield call([ref, ref.listAll]);

  return files;
}
*/

function* createCharacter(data) {
  //TODO CREATE unique id like SAGAS HERITAGE
  const { reference, characterData, pictureState } = data.payload;
  const pictureName = pictureState.name;
  if (pictureState) {
    const storageFolderPath = `quests/${reference.questKey}/${reference.scenarioId}/characters`;
    const storagePath = `${storageFolderPath}/${pictureName}`;

    try {
      //no-cache, max-age=0
      yield rsf.storage.uploadFile(
        storagePath,
        pictureState,
        scenarioService.DEFAULT_STORAGE_METADATA
      );
      // TODO characterID = characterNAME.
      const character = {
        ...characterData,
        avatar: pictureState.name,
        avatarUrl: getStoragePublicPath(storagePath),
        id: characterData.name.fr,
      };
      yield put(actions.createCharacterSuccess(character));
    } catch (error) {
      console.log(error);
      yield put(actions.loadError(error));
    }
  }
}

function* updateCharacter(data) {
  const { reference, character, pictureState, imageSelected } = data.payload;
  let characterNewData = character;
  let task;

  const id = Math.random().toString(36).substr(2, 9);

  try {
    if (pictureState !== null) {
      yield rsf.storage.uploadFile(
        `quests/${reference.questKey}/${reference.scenarioId}/characters/${character.avatar}`,
        pictureState,
        scenarioService.DEFAULT_STORAGE_METADATA
      );
    }
    if (imageSelected) {
      const templateFileResponse = yield fetch(imageSelected.picture);
      const templateFileContent = yield templateFileResponse.blob();

      task = rsf.storage.uploadFile(
        `quests/${reference.questKey}/${
          reference.scenarioId
        }/characters/${character.name.fr.toLowerCase()}_${id}.${templateFileContent.type.substr(
          6
        )}`,
        templateFileContent,
        scenarioService.DEFAULT_STORAGE_METADATA
      );
      yield task;
      characterNewData.avatarUrl = `https://storage.googleapis.com/granaquestbackend.appspot.com/quests/${
        reference.questKey
      }/${
        reference.scenarioId
      }/characters/${character.name.fr.toLowerCase()}_${id}.${templateFileContent.type.substr(
        6
      )}`;
      characterNewData.avatar = `${character.name.fr.toLowerCase()}_${id}.${templateFileContent.type.substr(
        6
      )}`;
    }

    yield put(actions.updateCharacterSuccess(characterNewData));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

function* deleteCharacter(data) {
  const { characterData } = data.payload;
  console.log(data);
  console.log(characterData.avatar.name);
  //  const file = characterData.avatar.name;
  try {
    yield put(actions.deleteCharacterSuccess(characterData));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

function loadPictureList(data) {
  const { questKey, scenarioId } = data.payload;
  //https://firebase.google.com/docs/storage/web/list-files
  const path = `quests/${questKey}/scenarios/${scenarioId}`;

  const listRef = firebase.storage().ref().child(`${path}/characters`);
  // Find all the prefixes and items.
  listRef
    .listAll()
    .then(function (res) {
      res.prefixes.forEach(function (folderRef) {
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
      });
      res.items.forEach(function (itemRef) {});
    })
    .catch(function (error) {
      console.log(error);
      console.log("erreur...");
    });

  //yield put(actions.getPictureListe(pictureListe));
}

function* loadLibraryCharacters() {
  try {
    const collectionRef = db.collection("characters");
    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    //Make a array for make a map() directly
    const charactersArray = [];

    yield all(
      Object.entries(data).map(function* (character) {
        // If need more informations => Character[1] = {with many informations...}
        const nom = character[1].name.fr;
        const picture = `https://storage.googleapis.com/granaquestbackend.appspot.com/library/characters/${character[1].fullPicture}`;

        charactersArray.push({ picture: picture, name: nom });
      })
    );

    yield put(actions.charactersLibraryLoadSuccess(charactersArray));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

function* chargeCharacterFromLibrary(data) {
  //Récuperer l'image.
  console.log(data);
  const { reference, character, imageSelected } = data.payload;
  let characterInfo = { ...character };

  try {
    const templateFileResponse = yield fetch(imageSelected.picture);
    const templateFileContent = yield templateFileResponse.blob();
    const task = rsf.storage.uploadFile(
      `quests/${reference.questKey}/${
        reference.scenarioId
      }/characters/${character.name.fr.toLowerCase()}.${templateFileContent.type.substr(
        6
      )}`,
      templateFileContent,
      scenarioService.DEFAULT_STORAGE_METADATA
    );
    characterInfo.avatarUrl = `https://storage.googleapis.com/granaquestbackend.appspot.com/quests/${
      reference.questKey
    }/${
      reference.scenarioId
    }/characters/${character.name.fr.toLowerCase()}.${templateFileContent.type.substr(
      6
    )}`;
    characterInfo.avatar = `${character.name.fr.toLowerCase()}.${templateFileContent.type.substr(
      6
    )}`;
    characterInfo.id = character.name.fr;
    yield task;
    yield put(actions.createCharacterSuccess(characterInfo));
  } catch (error) {
    console.log(error);
  }
  // exectuer la fonction createCharacter. en lui passant l'image et les informations.
  //
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_CHARACTER, createCharacter),
    takeEvery(actions.UPDATE_CHARACTER, updateCharacter),
    takeEvery(actions.DELETE_CHARACTER, deleteCharacter),
    takeEvery(actions.LOAD_PICTURE_LIST, loadPictureList),
    takeEvery(actions.LOAD_CHARACTER_LIBRARY, loadLibraryCharacters),
    takeEvery(
      actions.CHARGE_CHARACTERE_FROM_LIBRARY,
      chargeCharacterFromLibrary
    ),
  ]);
}
