export default interface EdgeModel {
    id: string;
    type: EdgeType;
    source: string;
    target: string;
}

export enum EdgeType {
    TRIGGER_ACTION_EDGE = "triggerActionEdge",
    REQUIRED_ACTION_EDGE = "requiredActionEdge",
}