export function createNFCNodeAndeTrigger({ editedNode, node, creation }) {
  const actionNode = {
    type: "actionNode",
    id: creation ? `activateNfc${editedNode.data.nfcId}` : node.id,
    data: {
      id: creation ? `activateNfc${editedNode.data.nfcId}` : node.id,
      actionType: "ToggleNfcActionModel",
      scenarioPath: `nfcs.${editedNode.data.nfcId}.actions.toggles.${
        creation ? `activateNfc${editedNode.data.nfcId}` : node.id
      }`,
      activate: true,
      nfcId: editedNode.data.nfcId,
      triggerActions: [],
    },
  };

  const edgeNodeToSource = {
    id: `${node.id}_to_${actionNode.id}`,
    type: "triggerActionEdge",
    source: node.id,
    target: actionNode.id,
  };

  const triggerNode = {
    id: `nfcs.${actionNode.id}.trigger`,
    type: "triggerNode",
    data: {
      id: `nfcs.${actionNode.id}.trigger`,
      triggerType: "NfcTriggerModel",
      scenarioPath: `nfcs.${editedNode.data.nfcId}.trigger`,
    },
  };

  const edgeNodeToTrigger = {
    id: `${actionNode.id}_to_${triggerNode.id}`,
    type: "requiredActionEdge",
    source: actionNode.id,
    target: triggerNode.id,
  };
  let objEdges;
  let objNodes;

  if (creation) {
    objNodes = {
      [actionNode.id]: actionNode,
      [triggerNode.id]: triggerNode,
    };
    objEdges = {
      [edgeNodeToSource.id]: edgeNodeToSource,
      [edgeNodeToTrigger.id]: edgeNodeToTrigger,
    };
  } else {
    objNodes = {
      [actionNode.id]: actionNode,
      [triggerNode.id]: triggerNode,
    };
  }

  return {
    objNodes,
    objEdges,
  };
}

export function getNfcState(scenario) {
  let nfcs = {};
  if (scenario.nfcs) {
    nfcs.data = scenario.nfcs;
  }

  return nfcs;
}
