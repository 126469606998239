import { all, put, call, takeEvery } from 'redux-saga/effects';
import actions from '../actions/actions';
import { rsf, db } from '@iso/lib/firebase/firebase';
import { convertCollectionsSnapshotToMap } from '@iso/lib/firebase/firebase.util';

function* loadUsers() {
  try {
    const usersRef = db.collection('users');
    const usersSnapshot = yield call(rsf.firestore.getDocument, usersRef);
    const users = yield call(convertCollectionsSnapshotToMap, usersSnapshot);
    yield put(actions.loadUsersSuccess(users));
  } catch (error) {
    console.log(error);
    yield put(actions.loadUsersError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_USERS, loadUsers)]);
}
