// Get all triggers from scenario
export function getInstructionsState(questKey, scenarioId, scenario) {
  let instructions = {};
  if (scenario.instructions) {
    instructions = scenario.instructions;
  }

  const instructionsState = {
    data: instructions,
    object: null,
    modalActive: false,
    isLoading: false,
  };

  return instructionsState;
}
