
enum TriggerType {
    /**
     * The player clicks on an AR button displayed when the scene is recognized
     */
    ARPickObjectTriggerModel = "ARPickObjectTriggerModel",

    /**
     * The AR scene has been recognized (the player has pointed is camera to the right scene)
     */
    ARTargetAugmentedTriggerModel = "ARTargetAugmentedTriggerModel",

    /**
     * The player selects the right answer in a multi-choice question asked by a character
     */
    AnswerSelectedTriggerModel = "AnswerSelectedTriggerModel",

    /**
     * The player enters the needed text in a free text question asked by a character
     */
    AnswerTextTriggerModel = "AnswerTextTriggerModel",

    /**
     * The player clicks on the start/resume button to start the chapter
     */
    ChapterInitTriggerModel = "ChapterInitTriggerModel",

    /**
     * The player closes the modal window displayed when a character is met or an object is found
     */
    CloseActivationModalTriggerModel = "CloseActivationModalTriggerModel",

    /**
     * The player combines two objects together
     */
    CombineObjectsTriggerModel = "CombineObjectsTriggerModel",

    /**
     * The player opens his bag and clicks for the first time to view the detail of a new object
     */
    FirstViewTriggerModel = "FirstViewTriggerModel",

    /**
     * The player enters or exit a geofence area
     */
    GeofenceTriggerModel = "GeofenceTriggerModel",

    /**
     * The player gives an object to a character
     */
    GiveObjectTriggerModel = "GiveObjectTriggerModel",

    /**
     * The player flashes the qrcode or touch with his phone the nfc sensor
     */
    NFCTriggerModel = "NfcTriggerModel",

    /**
     * The player has done the right movement/sound to activate his phone sensor (e.g. flip his phone)
     */
    SensorDetectTriggerModel = "SensorDetectTriggerModel",

    /**
     * The player has entered the right code to unlock an object
     */
    UnlockTriggerModel = "UnlockTriggerModel",
}
export default TriggerType
