import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Quests from '../QuestsDashboard/reducers/reducers';
import Quest from '../Quest/reducers/reducers';
import Scenarios from '../Scenarios/reducers/reducers';
import Games from '../Games/reducers/reducers';
import Users from '../Users/reducers/reducers';
import Profile from '../authentication/reducers/profileReducer';
import Modals from '../common/reducers/reducers';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  Ecommerce,
  LanguageSwitcher,
  Quests,
  Quest,
  Modals,
  Scenarios,
  Games,
  Users,
  Profile,
});
