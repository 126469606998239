import { all, put, call, takeEvery } from 'redux-saga/effects';
import actions from '../actions/actions';
import { rsf } from '@iso/lib/firebase/firebase';
import { createJson } from '../../common/functions';
import 'firebase/firestore';

function* changeMarkerLocation(data) {
  const { chapterId, chaptersData, location } = data.payload;
  try {
    chaptersData &&
      chaptersData.items.map(chapter => {
        if (chapterId === chapter.id) {
          chapter.location = location;
        }
        return '';
      });
    yield put(actions.updateLocationMarkerSuccess(chaptersData));
  } catch (error) {
    console.log(error);
  }
}

function* updateChapter(data) {
  const { reference, chapterData, firstChapterData } = data.payload;
  let clone = chapterData;
  try {
    yield call(
      rsf.firestore.setDocument,
      `quests/${reference.questKey}/scenarios/${reference.scenarioId}`,
      {
        chapters: {
          firstChapterId: firstChapterData,
          items: {
            [clone.id]: {
              ...clone,
            },
          },
        },
      },
      { merge: true }
    );

    yield put(actions.updateChapterSuccess(chapterData));
  } catch (error) {
    console.log(error);
    yield put(actions.loadError(error));
  }
}

function* updateMarkersFirebase(data) {
  const { chaptersData, reference } = data.payload;
  let dataToSend;
  try {
    yield all(
      chaptersData.items.map(function* (chapter) {
        if (chapter.message) {
          delete chapter.message;
        }
        dataToSend = yield call(
          rsf.firestore.setDocument,
          `quests/${reference.questKey}/scenarios/${reference.scenarioId}`,
          {
            chapters: {
              items: {
                [chapter.id]: chapter,
              },
            },
          },
          { merge: true }
        );
      })
    );
    yield put(actions.updateLocationMarkerSuccess(dataToSend));
    yield call(createJson, data);
  } catch (error) {
    console.log(error);
    yield put(actions.loadError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_CHAPTER, updateChapter),
    takeEvery(actions.UPDATE_LOCATION_MARKER, changeMarkerLocation),
    takeEvery(actions.UPDATE_LOCATION_FIREBASE, updateMarkersFirebase),
  ]);
}
