import { all, put, call, takeEvery } from 'redux-saga/effects';
import actions from '../actions/actions';
import { rsf } from '@iso/lib/firebase/firebase';
import 'firebase/firestore';
import { extractArExperienceTargetsAndAugmentations } from '../services/ARservice';
import { getStoragePublicPath } from '@iso/lib/firebase/firebase.util';

function readAsText(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

function* createARScene(data) {
  let files = [];
  let augmentation;
  const { zipState, aRData, reference } = data.payload;
  let id;

  if (aRData.id) {
    id = aRData.id;
  } else {
    id = Math.random()
      .toString(36)
      .substr(2, 9);
  }

  try {
    let projectFile;

    const templateFileResponse = yield fetch(
      getStoragePublicPath('quests/templates/ar/template.js')
    );
    const templateFileContent = yield templateFileResponse.text();
    // template.blod()
    console.log(templateFileContent);
    console.log('change');
    yield all(
      !aRData.files
        ? zipState.map(function*(file) {
            if (file.size === 0) {
              return;
            } else {
              files.push(file.name);
              if (file.name === 'project.js') {
                projectFile = file;
              }
              let task;
              if (file.name === 'scripts/template.js') {
                console.log('a changer');
                const newFile = new File([templateFileContent], 'template.js', {
                  type: 'application/javascript',
                });
                task = rsf.storage.uploadFile(
                  `quests/${reference.questKey}/${reference.scenarioId}/ar-experiences/${id}/${file.name}`,
                  newFile
                  // (pour library) newfile = blob.
                );
              } else {
                task = rsf.storage.uploadFile(
                  `quests/${reference.questKey}/${reference.scenarioId}/ar-experiences/${id}/${file.name}`,
                  file
                );
                yield task;
              }
            }
          })
        : (files = aRData.files)
    );
    if (projectFile) {
      const projectFileContent = yield call(readAsText, projectFile);
      augmentation = extractArExperienceTargetsAndAugmentations(
        aRData,
        projectFileContent
      );
    }
    // Parse the template file to extract targets and augmentations
    yield files.map(file => {
      if (file.includes('/')) {
        //   let aRId = file.substring(0, file.indexOf('/'));
      }
      return '';
    });
    // yield call(loadAR, data);
    //  yield call(createJson, data);
    yield put(
      actions.createARSceneSuccess({
        aRData: aRData,
        files: files,
        id: id,
        augmentation: augmentation,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadError(error));
  }
}
/*
function* updateAR(data) {
  let files = [];
  const { reference, zipState, aRData } = data.payload;
  const clone = Object.assign({}, aRData);
  delete clone.key;
  try {
    if (zipState !== null) {
      yield all(
        aRData.files.map(function*(file) {
          yield storageRef(data, 'ar-experiences', file)
            .delete()
            .then(() => console.log('File deleted successfully!'))
            .catch(error => console.log(error));
        }),
        zipState.map(function*(file) {
          if (file.size === 0) {
            return;
          } else {
            files.push(file.name);
            const task = rsf.storage.uploadFile(
              `quests/${reference.questKey}/${reference.scenarioId}/ar-experiences/${file.name}`,
              file
            );
            yield task;
          }
        })
      );
    }
    yield call(
      rsf.firestore.setDocument,
      `quests/${reference.questKey}/scenarios/${reference.scenarioId}`,
      {
        aRExperiences: {
          [clone.id]: {
            files: files,
            ...clone,
          },
        },
      },
      { merge: true }
    );
    // yield call(loadAR, data);
    yield call(createJson, data);
  } catch (error) {
    yield put(actions.loadError(error));
  }
}*/

function* deleteAR(data) {
  const { aRData } = data.payload;
  console.log(aRData.id);
  try {
    /*  yield all(
      aRData.files.map(function*(file) {
        yield storageRef(data, `ar-experiences/${aRData.id}`, file)
          .delete()
          .then(() => console.log('File deleted successfully!'))
          .catch(error => console.log(error));
      })
    );*/
    // yield call(loadAR, data);
    yield put(actions.deleteARSucess(aRData));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

export default function* rootSaga() {
  yield all([
    //  takeEvery(actions.LOAD_AR, loadAR),
    takeEvery(actions.CREATE_AR, createARScene),
    //  takeEvery(actions.UPDATE_AR, updateAR),
    takeEvery(actions.DELETE_AR, deleteAR),
  ]);
}
