const actions = {
  TOGGLE_MODAL: "TOGGLE_MODAL",
  INPUT_CHARACTER: "INPUT_CHARACTER",
  CREATE_CHARACTER: "CREATE_CHARACTER",
  CREATE_CHARACTER_SUCCESS: "CREATE_CHARACTER_SUCCESS",
  UPDATE_CHARACTER: "UPDATE_CHARACTER",
  UPDATE_CHARACTER_SUCCESS: "UPDATE_CHARACTER_SUCCESS",
  DELETE_CHARACTER: "DELETE_CHARACTER",
  DELETE_CHARACTER_SUCCESS: "DELETE_CHARACTER_SUCCESS",
  LOAD_ERROR: "LOAD_ERROR",
  GET_PICTURE_LIST: "GET_PICTURE_LIST",
  LOAD_PICTURE_LIST: "LOAD_PICTURE_LIST",
  LOAD_CHARACTER_LIBRARY: "LOAD_CHARACTER_LIBRARY",
  LOAD_LIBRARY_CHARACTERS_SUCCESS: "LOAD_LIBRARY_CHARACTERS_SUCCESS",
  CHARGE_CHARACTERE_FROM_LIBRARY: "CHARGE_CHARACTERE_FROM_LIBRARY",
  CHARGE_CHARACTERE_FROM_LIBRARY_SUCCESS:
    "CHARGE_CHARACTERE_FROM_LIBRARY_SUCCESS",

  chargeCharactereFromLibrary: (data) => ({
    type: actions.CHARGE_CHARACTERE_FROM_LIBRARY,
    payload: data,
  }),

  chargeCharactereFromLibrarySuccess: (data) => ({
    type: actions.CHARGE_CHARACTERE_FROM_LIBRARY_SUCCESS,
    payload: data,
  }),

  charactersLibraryLoad: () => ({
    type: actions.LOAD_CHARACTER_LIBRARY,
  }),

  charactersLibraryLoadSuccess: (data) => ({
    type: actions.LOAD_LIBRARY_CHARACTERS_SUCCESS,
    payload: data,
  }),

  updateCharacterSuccessLibrary: (data) => ({
    type: actions.LOAD_LIBRARY_CHARACTERS_SUCCESS,
    payload: data,
  }),

  loadCharacters: (reference) => ({
    type: actions.LOAD_CHARACTERS,
    payload: { reference },
  }),

  loadPictureList: (data) => ({
    type: actions.LOAD_PICTURE_LIST,
    payload: data,
  }),

  getPictureListe: (data) => ({
    type: actions.GET_PICTURE_LIST,
    payload: data,
  }),

  loadCharactersSuccess: (data) => ({
    type: actions.LOAD_CHARACTERS_SUCCESS,
    payload: { data },
  }),

  loadError: (error) => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  toggleModal: (data) => ({
    type: actions.TOGGLE_MODAL,
    payload: { data },
  }),

  inputCharacter: (data) => ({
    type: actions.INPUT_CHARACTER,
    payload: { data },
  }),

  createCharacter: (pictureState, characterData, reference) => ({
    type: actions.CREATE_CHARACTER,
    payload: { pictureState, characterData, reference },
  }),

  createCharacterSuccess: (data) => ({
    type: actions.CREATE_CHARACTER_SUCCESS,
    payload: { data },
  }),

  updateCharacter: ({ pictureState, character, reference, imageSelected }) => ({
    type: actions.UPDATE_CHARACTER,
    payload: { pictureState, character, reference, imageSelected },
  }),

  updateCharacterSuccess: (data) => ({
    type: actions.UPDATE_CHARACTER_SUCCESS,
    payload: { data },
  }),

  deleteCharacter: (characterData, reference) => ({
    type: actions.DELETE_CHARACTER,
    payload: { characterData, reference },
  }),
  deleteCharacterSuccess: (data) => ({
    type: actions.DELETE_CHARACTER_SUCCESS,
    payload: { data },
  }),
};

export default actions;
