// Get all triggers from scenario
export function getVideosState(questKey, scenarioId, scenario) {
  let videos = {};
  if (scenario.chapters) {
    videos = scenario.videos;
  }

  for (const videosId in videos) {
    const video = videos[videosId];
    const { source } = video;
    videos[
      videosId
    ].videoUrl = `https://storage.googleapis.com/granaquestbackend.appspot.com/quests/${questKey}/${scenarioId}/videos/${source}`;
  }
  const videosState = {
    data: videos,
    object: null,
    modalActive: false,
    isLoading: false,
  };

  return videosState;
}
