import actions from '../actions/actions';

const initialState = {
  modalActive: false,
  data: {},
  character: {
    characterId: '',
    description: {
      fr: '',
    },
    name: {
      fr: '',
    },
  },
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.LOAD_LIBRARY_CHARACTERS_SUCCESS:
      console.log('charge library');
      return {
        ...state,
        library: payload,
      };

    case actions.CHARGE_CHARACTERE_FROM_LIBRARY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.TOGGLE_MODAL:
      return {
        ...state,
        modalActive: payload.data.modal,
        character:
          payload.data == null ? initialState.character : payload.data.row,
      };
    case actions.GET_PICTURE_LIST:
      return {
        ...state,
        fileListe: payload.data,
      };
    case actions.INPUT_CHARACTER:
      return {
        ...state,
        character: payload.data,
      };
    case actions.CREATE_CHARACTER:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.CREATE_CHARACTER_SUCCESS:
      console.log(payload);
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [payload.data.id]: payload.data,
        },
        errorMessages: false,
      };
    case actions.UPDATE_CHARACTER:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.UPDATE_CHARACTER_SUCCESS:
      console.log(payload);
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [payload.data.id]: payload.data,
        },
        character: null,
        errorMessages: false,
      };
    case actions.DELETE_CHARACTER:
      return {
        ...state,
        isLoading: true,

        errorMessages: false,
      };
    case actions.DELETE_CHARACTER_SUCCESS:
      console.log('success');
      console.log(payload.data.id);
      const newData = state.data;
      delete newData[payload.data.id];
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
