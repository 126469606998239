import { Reducer } from 'react';
import actions, { LoadErrorAction, LoadSingleQuestAction, LoadSingleQuestSuccessAction, QuestActionTypes, UpdateInputAction, UpdateQuestAction, UpdateQuestSuccessAction } from '../actions/actions';
import { QuestState } from '../models/QuestState';



const initialState: QuestState = {
  isLoading: false,
  errorMessages: undefined,
  quest: {},
  key: '',
};

const questReducers: Reducer<QuestState, QuestActionTypes> = (state: QuestState = initialState, action: QuestActionTypes) => {
  const { type } = action;
  switch (type) {
    case actions.LOAD_ERROR:
      const loadErrorAction = action as LoadErrorAction;
      return {
        ...state,
        isLoading: false,
        errorMessages: loadErrorAction.payload.error,
      };
    case actions.LOAD_SINGLE_QUEST:
      const loadSingleQuestAction = action as LoadSingleQuestAction;
      return {
        ...state,
        isLoading: true,
        errorMessages: undefined,
        key: loadSingleQuestAction.payload.questKey,
      };
    case actions.LOAD_SINGLE_QUEST_SUCCESS:
      const loadSingleQuestSuccessAction = action as LoadSingleQuestSuccessAction;
      return {
        ...state,
        isLoading: false,
        errorMessages: undefined,
        quest: loadSingleQuestSuccessAction.payload.quest,
      };
    case actions.UPDATE_INPUT:
      const updateInputAction = action as UpdateInputAction;
      return {
        ...state,
        quest: updateInputAction.payload.quest,
      };
    case actions.UPDATE_QUEST:
      const updateQuestAction = action as UpdateQuestAction;
      return {
        ...state,
        isLoading: true,
        errorMessages: undefined,
      };
    case actions.UPDATE_QUEST_SUCCESS:
      const updateQuestSuccessAction = action as UpdateQuestSuccessAction;
      return {
        ...state,
        isLoading: false,
        quest: updateQuestSuccessAction.payload.quest,
        errorMessages: undefined,
      };
    case actions.UPDATE_QUEST_PICTURE:
      return {
        ...state,
        isLoading: true,
        errorMessages: undefined,
      };
    default:
      return state;
  }
}
export default questReducers;
