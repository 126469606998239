import actions from "../actions/actions";

const initialState = {
  isLoading: false,
  errorMessages: false,
  modalActive: false,
  chapters: {},
  chapter: null,
  quest: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.TOGGLE_MODAL_CHAPTERS:
      return {
        ...state,
        modalActive: !state.modalActive,
        chapter: payload.data == null ? initialState.chapter : payload.data,
      };
    case actions.LOAD_CHAPTERS:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.LOAD_CHAPTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chapters: payload.data,
        errorMessages: false,
      };
    case actions.LOAD_SINGLE_QUEST:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.LOAD_SINGLE_QUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessages: false,
        quest: payload.data,
      };
    case actions.INPUT_CHAPTER:
      return {
        ...state,
        chapter: payload.data,
      };
    case actions.INPUT_PREV_CHAPTER:
      return {
        ...state,
        previousChapter: payload.data,
      };
    case actions.CHANGE_FIRST_CHAPTER:
      return {
        ...state,
        chapters: payload.data,
      };
    case actions.CREATE_CHAPTER:
      let id;
      if (payload.chapter.id) {
        id = payload.chapter.id;
      } else {
        id = Math.random().toString(36).substr(2, 9);
      }
      return {
        ...state,
        data: {
          ...state.data,
          items: {
            ...state.data.items,
            [id]: {
              ...payload.chapter,
              id: id,
            },
          },
        },
      };
    case actions.CREATE_CHAPTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chapters: payload.characterData,
        errorMessages: false,
      };
    case actions.UPDATE_LOCATION_MARKER:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.UPDATE_LOCATION_MARKER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chapters: payload.data,
        errorMessages: false,
      };
    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessages: payload.error,
      };
    default:
      return state;
  }
}
