const actions = {
  ADD_INSTRUCTION: 'ADD_INSTRUCTION',
  ADD_INSTRUCTION_SUCCESS: 'ADD_INSTRUCTION_SUCCESS',

  addInstruction: data => ({
    type: actions.ADD_INSTRUCTION,
    payload: data,
  }),
  addInstructionSuccess: data => ({
    type: actions.ADD_INSTRUCTION_SUCCESS,
    payload: data,
  }),
};

export default actions;
