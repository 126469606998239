import { rsf, db } from '@iso/lib/firebase/firebase';
import { call } from 'redux-saga/effects';
import firebase from 'firebase/app';

// add data to redux store

export default function splitNameState(name, value, data) {
  const nameParts = name.split('.');
  const newDataState = { ...data };
  let newDataStateVar = newDataState;
  for (let iNamePart = 0; iNamePart < nameParts.length - 1; iNamePart++) {
    const varKey = nameParts[iNamePart];
    if (!newDataStateVar[varKey]) {
      newDataStateVar[varKey] = {};
    }
    newDataStateVar = newDataStateVar[varKey];
  }
  const newDataStateLastKey = nameParts[nameParts.length - 1];
  newDataStateVar[newDataStateLastKey] = value;
  return newDataState;
}

// common functions for sagas

export function documentRef(data) {
  const { reference } = data.payload;
  return db
    .collection('quests')
    .doc(`${reference.questKey}`)
    .collection('scenarios')
    .doc(`${reference.scenarioId}`);
}

export function storageRef(data, folder, file) {
  const { reference } = data.payload;
  return firebase
    .storage()
    .ref()
    .child(
      `quests/${reference.questKey}/${reference.scenarioId}/${folder}/${file}`
    );
}

export function* createJson(data) {
  const { reference } = data.payload;
  const snapshot = yield call(rsf.firestore.getDocument, documentRef(data));

  let scenario = snapshot.data();
  let jsonExport = JSON.stringify(scenario);
  const blob = new Blob([jsonExport], { type: 'application/json' });
  yield rsf.storage.uploadFile(
    `quests/${reference.questKey}/${reference.scenarioId}/scenario.json`,
    blob
  );
}

export function stringBetweenDots(str) {
  const regex = /\.([^\.]+)\./;
  return regex.exec(str);
}
