import actions from '../actions/actions';

const initialState = {
  isLoading: false,
  errorMessages: false,
  modalActive: false,
  objects: {},
  object: {
    description: {
      fr: '',
    },
    title: {
      fr: '',
    },
  },
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.LOAD_OBJECTS:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.LOAD_OBJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        objects: payload.data,
        errorMessages: false,
      };
    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessages: payload.error,
      };
    case actions.TOGGLE_MODAL_OBJECT:
      return {
        ...state,
        modalActive: payload.data.modal,
        object: payload.data == null ? initialState.object : payload.data.row,
      };
    case actions.INPUT_OBJECT:
      return {
        ...state,
        object: payload.data,
      };
    case actions.CREATE_OBJECT:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.CREATE_OBJECT_SUCCESS:
      console.log(payload);
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [payload.data.id]: payload.data,
        },
        errorMessages: false,
      };
    case actions.UPDATE_OBJECT:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.UPDATE_OBJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [payload.data.id]: payload.data,
        },
        errorMessages: false,
      };
    case actions.DELETE_OBJECT:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
      };
    case actions.DELETE_OBJECT_SUCCESS:
      console.log('success object');
      console.log(payload.id);
      const newData = state.data;
      delete newData[payload.id];
      return {
        ...state,
        data: newData,
        isLoading: false,
      };
    case actions.LOAD_OBJECT_LIBRARY:
      return {
        ...state,
        isLoading: false,
        library: [],
      };

    case actions.LOAD_OBJECT_LIBRARY_SUCCESS:
      console.log(payload);
      return {
        ...state,
        library: payload,
      };
    case actions.CHARGE_OBJECT_FROM_LIBRARY:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
}
