// Get all triggers from scenario
export function getARState(questKey, scenarioId, scenario) {
  let arScenes = {};
  if (scenario.aRExperiences) {
    arScenes = scenario.aRExperiences;
  }

  const arSceneState = {
    data: arScenes,
    arScene: { instruction: { fr: "" } },
    modalActive: false,
    isLoading: false,
  };

  return arSceneState;
}
//Create toggle Actions =>
//Fonction pour créer les deux actions "activate/disable" Arexperience createActivateActions() { ... } ou createDesactivateActions

export function extractArExperienceTargetsAndAugmentations(
  arExperience,
  projectFileContent
) {
  //logger.debug(projectFileContent);
  let projectJsonRegexp = new RegExp("var projectJSONOffline =\\n([^;]*)");
  let projectJsonMatch = projectFileContent.match(projectJsonRegexp);

  if (projectJsonMatch === undefined) {
    console.log("AR project json not found");
  } else {
    if (arExperience) {
      arExperience.targets = [];
      arExperience.augmentations = [];
      const projectJson = JSON.parse(projectJsonMatch[1]);

      for (let iTarget in projectJson.targets) {
        let target = projectJson.targets[iTarget];

        if (!arExperience.targets.includes(target.name)) {
          arExperience.targets.push(target.name);
        }

        for (let iAugmentation in target.augmentations) {
          let augmentation = target.augmentations[iAugmentation];
          // Add the augmentation in the scenario file
          if (
            !arExperience.augmentations.includes(augmentation.properties.name)
          ) {
            arExperience.augmentations.push(augmentation.properties.name);
          }
        }
      }
    } else {
      console.log("Null AR experience");
    }
  }
}

export function createNodesAndEdges(
  nodeSourceSelected,
  arExperienceId,
  arExperience
) {
  // Create nodes
  // Create activation node
  const activateId = `aRExperiences.${arExperienceId}.actions.toggleAR.activate${arExperienceId}`;
  const activateARScene = {
    actionType: "ToggleARActionModel",
    aRId: arExperienceId,
    activate: true,
    id: `${activateId}`,
    scenarioPath: activateId,
    triggerActions: [],
    waitBefore: 0,
  };
  // TODO: Change title
  const activateARSceneNode = {
    type: "actionNode",
    id: activateId,
    title: `${arExperienceId}.ARTargetAugmentedTriggerModel`,
    data: activateARScene,
  };

  // TODO: For each target create an augmented target trigger

  const targetId = arExperience.targets[0];
  const arTargetAugmentedTriggerModelId = `aRExperiences.${arExperienceId}.triggers.augmentedARTargets.${targetId}`;
  const ARTargetAugmentedTriggerModel = {
    type: "triggerNode",
    id: arTargetAugmentedTriggerModelId,
    aRId: arExperienceId,
    title: `${targetId}`,
    data: {
      requireActions: [activateId],
      scenarioPath: arTargetAugmentedTriggerModelId,
      triggerType: "ARTargetAugmentedTriggerModel",
      id: arTargetAugmentedTriggerModelId,
    },
  };

  // TODO: For each augmentation, create a trigger
  const augmentationId = arExperience.augmentations[0];
  const arPickObjectTriggerModelId = `aRExperiences.${arExperienceId}.triggers.pickARObjects.${augmentationId}`;
  const ARPickObjectTriggerModel = {
    type: "triggerNode",
    id: arPickObjectTriggerModelId,
    title: `${augmentationId}`,
    data: {
      requireActions: [activateId],
      triggerType: "ARPickObjectTriggerModel",
      scenarioPath: arPickObjectTriggerModelId,
      id: arPickObjectTriggerModelId,
    },
  };

  //Create edges :
  const EdgeToNodeSelected = {
    id: `${nodeSourceSelected}_to_${activateId}`,
    source: nodeSourceSelected.id,
    target: activateId,
    type: "triggerActionEdge",
  };

  const ARPickObjectTriggerModelEdge = {
    id: `${activateId}_${arExperienceId}_pick`,
    source: activateId,
    target: ARPickObjectTriggerModel.id,
    type: "requiredActionEdge",
  };

  const ARTargetAugmentedTriggerModelEdge = {
    id: `${activateId}_${arExperienceId}_target`,
    source: activateId,
    target: ARTargetAugmentedTriggerModel.id,
    type: "requiredActionEdge",
  };

  let edges = {
    [EdgeToNodeSelected.id]: EdgeToNodeSelected,
    [ARTargetAugmentedTriggerModelEdge.id]: ARTargetAugmentedTriggerModelEdge,
    [ARPickObjectTriggerModelEdge.id]: ARPickObjectTriggerModelEdge,
  };

  let nodes = {
    [activateId]: activateARSceneNode,
    [ARTargetAugmentedTriggerModel.id]: ARTargetAugmentedTriggerModel,
    [ARPickObjectTriggerModel.id]: ARPickObjectTriggerModel,
  };

  //If Instruction :
  let nodeInstruction;
  let edgeSourceToInstruction;

  if (arExperience.instructionId) {
    //Create Node Instruction.
    nodeInstruction = {
      id: `instructions.${arExperience.instructionId}.actions.show`,
      type: "actionNode",
      data: {
        id: `instructions.${arExperience.instructionId}.actions.show`,
        actionType: "ShowInstructionActionModel",
        scenarioPath: `instructions.${arExperience.instructionId}.actions.show`,
      },
    };
    //Create edges Instruction:
    edgeSourceToInstruction = {
      id: `${nodeSourceSelected.id}_to_${nodeInstruction.id}`,
      source: nodeSourceSelected.id,
      target: nodeInstruction.id,
      type: "triggerActionEdge",
    };
    nodes = {
      ...nodes,
      [nodeInstruction.id]: nodeInstruction,
    };
    edges = {
      ...edges,
      edgeSourceToInstruction,
    };
  }

  return {
    edges: edges,
    nodes: nodes,
  };
}
