const actions = {
  TOGGLE_MODAL_CHAPTERS: 'TOGGLE_MODAL_CHAPTERS',
  LOAD_CHAPTERS: 'LOAD_CHAPTERS',
  LOAD_CHAPTERS_SUCCESS: 'LOAD_CHAPTERS_SUCCESS',
  LOAD_ERROR: 'LOAD_ERROR',
  INPUT_CHAPTER: 'INPUT_CHAPTER',
  CHANGE_FIRST_CHAPTER: 'CHANGE_FIRST_CHAPTER',
  CREATE_CHAPTER: 'CREATE_CHAPTER',
  CREATE_CHAPTER_SUCCESS: 'CREATE_CHAPTER_SUCCESS',
  UPDATE_LOCATION_MARKER: 'UPDATE_LOCATION_MARKER',
  UPDATE_LOCATION_MARKER_SUCCESS: 'UPDATE_LOCATION_MARKER_SUCCESS',
  UPDATE_LOCATION_FIREBASE: 'UPDATE_LOCATION_FIREBASE',
  UPDATE_LOCATION_FIREBASE_SUCCESS: 'UPDATE_LOCATION_FIREBASE_SUCCESS',
  UPDATE_CHAPTER: 'UPDATE_CHAPTER',
  UPDATE_CHAPTER_SUCCESS: 'UPDATE_CHAPTER_SUCCESS',

  toggleModalChapters: (data = null) => ({
    type: actions.TOGGLE_MODAL_CHAPTERS,
    payload: { data },
  }),

  loadChapters: reference => ({
    type: actions.LOAD_CHAPTERS,
    payload: { reference },
  }),

  loadChaptersSuccess: data => ({
    type: actions.LOAD_CHAPTERS_SUCCESS,
    payload: { data },
  }),

  inputChapter: data => ({
    type: actions.INPUT_CHAPTER,
    payload: { data },
  }),

  changeFirstChapter: data => ({
    type: actions.CHANGE_FIRST_CHAPTER,
    payload: { data },
  }),

  createChapter: data => ({
    type: actions.CREATE_CHAPTER,
    payload: data,
  }),

  createChapterSuccess: data => ({
    type: actions.CREATE_CHAPTER_SUCCESS,
    payload: { data },
  }),

  updateLocationMarker: (location, chapterId, chaptersData) => ({
    type: actions.UPDATE_LOCATION_MARKER,
    payload: { location, chapterId, chaptersData },
  }),

  updateLocationMarkerSuccess: data => ({
    type: actions.UPDATE_LOCATION_MARKER_SUCCESS,
    payload: { data },
  }),

  updateLocationFirebase: (chaptersData, reference) => ({
    type: actions.UPDATE_LOCATION_FIREBASE,
    payload: { chaptersData, reference },
  }),

  updateLocationFirebaseSuccess: data => ({
    type: actions.UPDATE_LOCATION_FIREBASE_SUCCESS,
    payload: { data },
  }),

  updateChapter: (firstChapterData, chapterData, reference) => ({
    type: actions.UPDATE_CHAPTER,
    payload: { firstChapterData, chapterData, reference },
  }),

  updateChapterSuccess: data => ({
    type: actions.UPDATE_CHAPTER_SUCCESS,
    payload: { data },
  }),

  loadError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),
};

export default actions;
