import { all, put, call, takeEvery } from "redux-saga/effects";
import actions from "../actions/actions";
import {
  consolidateData,
  getActions,
  getTriggers,
  getActionsAndDependingTriggers,
  makeGraph,
} from "../services/ScenarioService";
import { getCharactersState } from "../../Characters/services/charactersService";
import { rsf, db } from "@iso/lib/firebase/firebase";
import { convertCollectionsSnapshotToMap } from "@iso/lib/firebase/firebase.util";
import { getObjectsState } from "../../Objects/services/ObjectService";
import { getChaptersState } from "../../Chapters/services/chaptersService";
import { getInstructionsState } from "../../Instructions/services/instructionsService";
import { getARState } from "../../AR/services/ARservice";
import { getSoundTracksState } from "../../Soundtracks/services/SoundsServices";
import { getVideosState } from "../../Videos/services/VideosServices";
import { getHeritagesState } from "../../Heritage/services/heritageServices";
import { getinformationsState } from "../../Games/services/GameServices";
import { getNfcState } from "../../NFC/services/NfcService";
import log from "../../common/services/LogService";

function* loadScenariosDatabase(questKey) {
  const { data } = questKey.payload;
  try {
    const collectionRef = db
      .collection("quests")
      .doc(`${data}`)
      .collection("scenarios");
    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    // TODO: do not load all data ?
    let dataRetrieved = yield call(convertCollectionsSnapshotToMap, snapshots);
    yield put(actions.loadScenariosSuccess(dataRetrieved));
  } catch (error) {
    console.log(error);
    yield put(actions.loadError(error));
  }
}

function* loadScenario(datas) {
  const allActions = {};
  const allTriggers = {};
  const allActionsAndDependingTriggers = {};

  try {
    console.log("Loading scenario");
    let scenario;

    const { questKey, scenarioKey, draftVersionName } = datas.payload.data;
    const documentRef = db
      .collection("quests")
      .doc(questKey)
      .collection("scenarios")
      .doc(scenarioKey);
    const prodSnapshot = yield call(rsf.firestore.getDocument, documentRef);
    const prod = prodSnapshot.data();

    const draftRf = db
      .collection("quests")
      .doc(questKey)
      .collection("scenarios")
      .doc(scenarioKey)
      .collection("drafts");
    const draftSnapshot = yield call(rsf.firestore.getDocument, draftRf);
    const drafts = yield call(convertCollectionsSnapshotToMap, draftSnapshot);

    if (draftVersionName && draftVersionName !== "") {
      scenario = drafts[draftVersionName];
    } else {
      scenario = prod;
    }
    scenario.id = scenarioKey;
    log.debug("Scenario loaded");
    log.debug(scenario);
    consolidateData(scenario);

    getActions(scenario, allActions);

    getTriggers(scenario, allTriggers);

    getActionsAndDependingTriggers(
      allActions,
      allTriggers,
      scenario,
      allActionsAndDependingTriggers
    );
    const allActionsCount = Object.keys(allActions).length;
    const allTriggersCount = Object.keys(allTriggers).length;
    log.info(`Found ${allActionsCount} actions and ${allTriggersCount} triggers in scenario`);

    const characters = getCharactersState(questKey, scenarioKey, scenario);
    const objects = getObjectsState(questKey, scenarioKey, scenario);
    const chapters = getChaptersState(questKey, scenarioKey, scenario);
    const instructions = getInstructionsState(questKey, scenarioKey, scenario);
    const aRExperiences = getARState(questKey, scenarioKey, scenario);
    const soundTracks = getSoundTracksState(questKey, scenarioKey, scenario);
    const videos = getVideosState(questKey, scenarioKey, scenario);
    const heritages = getHeritagesState(questKey, scenarioKey, scenario);
    const informations = getinformationsState(questKey, scenarioKey, scenario);
    const nfcs = getNfcState(scenario);

    // TODO: nfc service, getNfcState
    const nodeGraphData = makeGraph(
      scenario,
      allTriggers,
      allActions,
      allActionsAndDependingTriggers,
      questKey,
      scenarioKey
    );

    // Clean draft to keep only light version in state
    for (let versionName in drafts) {
      const draft = drafts[versionName];
      drafts[versionName] = {
        version: draft.version,
        updatedTime: draft.updatedTime,
      };
    }

    yield put(
      actions.loadScenarioSuccess({
        drafts,
        selectedDraftVersionName: draftVersionName,
        informations,
        characters,
        chapters,
        instructions,
        aRExperiences,
        heritages,
        nfcs,
        objects,
        soundTracks,
        videos,
        scenario,
        nodeGraph: {
          data: nodeGraphData,
        },
      })
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadError(error));
  }
}

function* updateNode(data) {
  const { reference, nodeData } = data.payload;
  const clone = Object.assign({}, nodeData);
  delete clone.data.id;
  try {
    if (nodeData.data.type === "writeDialogFrom") {
      yield call(
        rsf.firestore.setDocument,
        `quests/${reference.questKey}/scenarios/${reference.scenarioId}`,
        {
          characters: {
            [nodeData.data.characterId]: {
              actions: {
                dialogs: {
                  [nodeData.id]: { ...clone.data },
                },
              },
            },
          },
        },
        { merge: true }
      );
    }
    yield put(actions.updateNodeSuccess(nodeData));
  } catch (error) {
    console.log(error);
    yield put(actions.loadError(error));
  }
}

function* saveDraftScenario(data) {
  const { reference, version } = data.payload;
  let newScenario = data.payload.newScenario;
  newScenario.scenarioId = reference.scenarioId;
  newScenario.version = version;
  //newScenario.updatedTime = firebase.firestore.Timestamp.now();

  yield call(
    rsf.firestore.setDocument,
    `quests/${reference.questKey}/scenarios/${reference.scenarioId}/drafts/${version.name}`,
    newScenario
  );

  yield put(actions.upddateScenarioSuccess(newScenario));
}

function* saveScenario(data) {
  const { reference } = data.payload;
  let newScenario = data.payload.newScenario;
  newScenario.scenarioId = reference.scenarioId;

  //newScenario.updatedTime = firebase.firestore.Timestamp.now();
  console.log(newScenario);
  yield call(
    rsf.firestore.setDocument,
    `quests/${reference.questKey}/scenarios/${reference.scenarioId}`,
    newScenario
  );

  yield put(actions.upddateScenarioSuccess(newScenario));
}

function* deleteDraft(data) {
  const id = data.payload.id;
  const reference = data.payload.reference;
  const drafts = data.payload.drafts;
  delete drafts[id];

  try {
    yield call(
      rsf.firestore.deleteDocument,
      `quests/${reference.questKey}/scenarios/${reference.scenarioId}/drafts/${id}`
    );
    yield put(actions.deleteDraftSuccess(drafts));
  } catch (error) {
    console.log(error);
    yield put(actions.loadError(error));
  }
}

function* changePicture(data) {
  console.log(data);
  const { orientation, file, questKey } = data.payload;
  const storageFolderPath = `quests/${questKey}/theme/${orientation}.jpg`;
  console.log(storageFolderPath);

  try {
    yield rsf.storage.uploadFile(storageFolderPath, file);
    yield put(actions.changePictureGameSuccess());
  } catch (error) {
    console.log(error);
  }
}
function* createNewScenario(data) {
  const { questKey, scenario, scenarioName } = data.payload;

  try {
    yield call(
      rsf.firestore.setDocument,
      //setdocument
      `quests/${questKey}/scenarios/${scenarioName}`,
      scenario
    );
    yield put(actions.createNewScenarioSuccess());
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_NEW_SCENARIO, createNewScenario),
    takeEvery(actions.CHANGE_PICTURE_THEME, changePicture),
    takeEvery(actions.LOAD_SCENARIOS, loadScenariosDatabase),
    takeEvery(actions.LOAD_SCENARIO, loadScenario),
    takeEvery(actions.UPDATE_NODE, updateNode),
    takeEvery(actions.SAVE_SCENARIO_DRAFT, saveDraftScenario),
    takeEvery(actions.SAVE_SCENARIO_VALIDATOR, saveScenario),
    takeEvery(actions.DELETE_DRAFT, deleteDraft),
  ]);
}
