export function getHeritagesState(questKey, scenarioId, scenario) {
  let heritages = {};
  if (scenario.heritage) {
    heritages = scenario.heritage;
  }

  for (const heritageId in heritages) {
    const heritage = heritages[heritageId];
    if (heritage.img) {
      heritage.imgUrl = `https://storage.googleapis.com/granaquestbackend.appspot.com/quests/${questKey}/${scenarioId}/heritages/${heritage.img}`;
    }
  }
  const heritagesState = {
    data: heritages,
    heritage: null,
    modalActive: false,
    isLoading: false,
  };

  return heritagesState;
}
