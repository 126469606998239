const actions = {
  LOAD_USERS: 'LOAD_USERS',
  LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
  LOAD_USERS_ERROR: 'LOAD_USERS_ERROR',

  loadUsers: () => {
    return { type: actions.LOAD_USERS };
  },
  loadUsersSuccess: data => ({
    type: actions.LOAD_USERS_SUCCESS,
    payload: { data },
  }),
  loadUsersError: error => ({
    type: actions.LOAD_USERS_ERROR,
    payload: { error },
  }),
};

export default actions;
