import actions from '../actions/actions';

const initialState = {
  isLoading: false,
  isLoadingSingle: false,
  isLoadingStats: false,
  errorMessages: false,
  stats: {},
  games: {},
  quest: undefined,
  singleGame: {},
  key: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.LOAD_STATS:
      return {
        ...state,
        isLoadingStats: true,
        errorMessages: false,
        key: payload.data,
      };
    case actions.LOAD_STATS_SUCCESS:
      return {
        ...state,
        isLoadingStats: false,
        errorMessages: false,
        stats: payload.data,
      };
    case actions.LOAD_STATS_ERROR:
      return {
        ...state,
        isLoadingStats: false,
        errorMessages: payload.error,
      };
    case actions.LOAD_GAMES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessages: payload.error,
      };
    case actions.LOAD_GAMES:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
        key: payload.data,
      };
    case actions.LOAD_GAMES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessages: false,
        games: payload.data,
      };
    case actions.LOAD_SINGLE_GAME:
      return {
        ...state,
        isLoadingSingle: true,
        errorMessages: false,
      };
    case actions.LOAD_SINGLE_GAME_SUCCESS:
      return {
        ...state,
        isLoadingSingle: false,
        errorMessages: false,
        singleGame: payload.data,
      };
    case actions.LOAD_SINGLE_GAME_ERROR:
      return {
        ...state,
        isLoadingSingle: false,
        errorMessages: payload.error,
      };
    case actions.LOAD_SINGLE_QUEST:
      return {
        ...state,
        isLoading: true,
        errorMessages: false,
        key: payload.data,
      };
    case actions.LOAD_SINGLE_QUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessages: false,
        quest: payload.data,
      };

    default:
      return state;
  }
}
