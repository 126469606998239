const actions = {
  LOAD_OBJECTS: 'LOAD_OBJECTS',
  LOAD_OBJECTS_SUCCESS: 'LOAD_OBJECTS_SUCCESS',
  LOAD_ERROR: 'LOAD_ERROR',
  TOGGLE_MODAL_OBJECT: 'TOGGLE_MODAL_OBJECT',
  INPUT_OBJECT: 'INPUT_OBJECT',
  CREATE_OBJECT: 'CREATE_OBJECT',
  CREATE_OBJECT_SUCCESS: 'CREATE_OBJECT_SUCCESS',
  UPDATE_OBJECT: 'UPDATE_OBJECT',
  UPDATE_OBJECT_SUCCESS: 'UPDATE_OBJECT_SUCCESS',
  DELETE_OBJECT: 'DELETE_OBJECT',
  DELETE_OBJECT_SUCCESS: 'DELETE_OBJECT_SUCCESS',
  LOAD_OBJECT_LIBRARY: 'LOAD_OBJECT_LIBRARY',
  LOAD_OBJECT_LIBRARY_SUCCESS: 'LOAD_OBJECT_LIBRARY_SUCCESS',
  CHARGE_OBJECT_FROM_LIBRARY: 'CHARGE_OBJECT_FROM_LIBRARY',

  chargeObjectFromLibrary: data => ({
    type: actions.CHARGE_OBJECT_FROM_LIBRARY,
    payload: data,
  }),

  objectsLibraryLoad: data => ({
    type: actions.LOAD_OBJECT_LIBRARY,
    payload: data,
  }),
  objectsLibraryLoadSuccess: data => ({
    type: actions.LOAD_OBJECT_LIBRARY_SUCCESS,
    payload: data,
  }),

  loadObjects: reference => ({
    type: actions.LOAD_OBJECTS,
    payload: { reference },
  }),

  loadObjectsSuccess: data => ({
    type: actions.LOAD_OBJECTS_SUCCESS,
    payload: { data },
  }),

  loadError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  toggleModalObject: data => ({
    type: actions.TOGGLE_MODAL_OBJECT,
    payload: { data },
  }),

  inputObject: data => ({
    type: actions.INPUT_OBJECT,
    payload: { data },
  }),

  createObject: (pictureState, objectData, reference) => ({
    type: actions.CREATE_OBJECT,
    payload: { pictureState, objectData, reference },
  }),

  createObjectSuccess: data => ({
    type: actions.CREATE_OBJECT_SUCCESS,
    payload: { data },
  }),

  updateObject: data => ({
    type: actions.UPDATE_OBJECT,
    payload: data,
  }),

  updateObjectSuccess: data => ({
    type: actions.UPDATE_OBJECT_SUCCESS,
    payload: { data },
  }),

  deleteObject: (objectData, reference) => ({
    type: actions.DELETE_OBJECT,
    payload: { objectData, reference },
  }),
  deleteObjectSuccess: data => ({
    type: actions.DELETE_OBJECT_SUCCESS,
    payload: data,
  }),
};

export default actions;
