import { all, put, takeEvery, call } from "redux-saga/effects";
import actions from "../actions/actions";
import { rsf, db } from "@iso/lib/firebase/firebase";
import "firebase/firestore";
import {
  getStoragePublicPath,
  convertCollectionsSnapshotToMap,
} from "@iso/lib/firebase/firebase.util";

function* createObject(data) {
  const { reference, objectData, pictureState } = data.payload;
  const pictureName = pictureState.name;
  const storagePath = `quests/${reference.questKey}/${reference.scenarioId}/objects/${pictureName}`;
  if (pictureState) {
    const pictureId = pictureState.name.substring(
      0,
      pictureState.name.indexOf(".")
    );
    try {
      yield rsf.storage.uploadFile(storagePath, pictureState);

      const object = {
        ...objectData,
        id: objectData.title.fr,
        pictureName: pictureName,
        avatar: pictureId,
        imgUrl: getStoragePublicPath(storagePath),
      };
      console.log(object);
      yield put(actions.createObjectSuccess(object));
    } catch (error) {
      yield put(actions.loadError(error));
    }
  }
}

function* updateObject(data) {
  console.log(data);
  const { reference, object, imageSelected, pictureState } = data.payload;
  let objectData = object;

  const clone = Object.assign({}, object);
  delete clone.imgUrl;
  delete clone.key;
  const pictureName = pictureState.name;
  const storagePath = `quests/${reference.questKey}/${reference.scenarioId}/objects/${pictureName}`;

  const id = Math.random().toString(36).substr(2, 9);

  try {
    if (pictureState) {
      yield rsf.storage.uploadFile(storagePath, pictureState);
    }
    if (imageSelected) {
      console.log("updapte from library");

      const templateFileResponse = yield fetch(imageSelected.picture);
      const templateFileContent = yield templateFileResponse.blob();

      const task = rsf.storage.uploadFile(
        `quests/${reference.questKey}/${
          reference.scenarioId
        }/objects/${objectData.title.fr.toLowerCase()}.${templateFileContent.type.substr(
          6
        )}_${id}`,
        templateFileContent
      );
      yield task;
      objectData.imgUrl = `https://storage.googleapis.com/granaquestbackend.appspot.com/quests/${
        reference.questKey
      }/${
        reference.scenarioId
      }/objects/${objectData.title.fr.toLowerCase()}.${templateFileContent.type.substr(
        6
      )}_${id}`;
      objectData.pictureName = `${object.title.fr.toLowerCase()}.${templateFileContent.type.substr(
        6
      )}`;
    }

    yield put(actions.updateObjectSuccess(objectData));
  } catch (error) {
    console.log(error);
    yield put(actions.loadError(error));
  }
}

function* deleteObject(data) {
  const { object } = data.payload;
  console.log(object.pictureName);
  console.log(data);
  try {
    /*
    yield storageRef(data, 'objects', objectData.pictureName)
      .delete()
      .then(() => console.log('File deleted successfully!'))
      .catch(error => console.log(error));
*/
    //  yield call(createJson, data);
    yield put(actions.deleteObjectSuccess(object));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

function* loadObjectsLibrary() {
  console.log("reducer");
  try {
    const collectionRef = db.collection("objects");
    console.log(collectionRef);
    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    //Make a array for make a map() directly
    const objectsArray = [];

    yield all(
      Object.entries(data).map(function* (object) {
        console.log(object);
        // If need more informations => Character[1] = {with many informations...}
        const nom = object[1].title.fr;
        const picture = `https://storage.googleapis.com/granaquestbackend.appspot.com/library/objects/${object[1].img}`;
        objectsArray.push({ picture: picture, name: nom });
      })
    );

    yield put(actions.objectsLibraryLoadSuccess(objectsArray));
  } catch (error) {
    console.log(error);
    yield put(actions.loadError(error));
  }
}

function* chargeObjetFromLibrary(data) {
  //Récuperer l'image.
  console.log(data);
  const { reference, object, imageSelected } = data.payload;
  console.log(object);
  let objectData = {
    ...object,
  };

  try {
    const templateFileResponse = yield fetch(imageSelected.picture);
    const templateFileContent = yield templateFileResponse.blob();
    const task = rsf.storage.uploadFile(
      `quests/${reference.questKey}/${
        reference.scenarioId
      }/objects/${objectData.title.fr.toLowerCase()}.${templateFileContent.type.substr(
        6
      )}`,
      templateFileContent
    );

    objectData.imgUrl = `https://storage.googleapis.com/granaquestbackend.appspot.com/quests/${
      reference.questKey
    }/${
      reference.scenarioId
    }/objects/${objectData.title.fr.toLowerCase()}.${templateFileContent.type.substr(
      6
    )}`;
    objectData.id = object.title.fr.toLowerCase();
    objectData.avatar = `${object.title.fr.toLowerCase()}`;
    objectData.img = `${object.title.fr.toLowerCase()}.${templateFileContent.type.substr(
      6
    )}`;
    yield task;
    yield put(actions.createObjectSuccess(objectData));
  } catch (error) {
    console.log(error);
  }
  // exectuer la fonction createCharacter. en lui passant l'image et les informations.
  //
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_OBJECT, createObject),
    takeEvery(actions.UPDATE_OBJECT, updateObject),
    takeEvery(actions.DELETE_OBJECT, deleteObject),
    takeEvery(actions.LOAD_OBJECT_LIBRARY, loadObjectsLibrary),
    takeEvery(actions.CHARGE_OBJECT_FROM_LIBRARY, chargeObjetFromLibrary),
  ]);
}
