import { all, put, call, takeEvery } from "redux-saga/effects";
import actions from "../actions/actions";
import { rsf } from "@iso/lib/firebase/firebase";
import "firebase/firestore";
import { documentRef } from "../../common/functions";
/*
function* loadHeritages(data) {
  const { reference } = data.payload;
  try {
    const snapshot = yield call(rsf.firestore.getDocument, documentRef(data));

    let scenario = snapshot.data();
    let heritages = [];
    let heritagesPictureSourceUrl = [];
    yield all(
      Object.keys(scenario)
        .filter((field) => field === "heritage")
        .map((field) => {
          return heritages.push(
            (heritages = {
              ...scenario[field],
            })
          );
        })
    );
    yield all(
      Object.values(heritages).map(function* (heritage) {
        if (heritage.img) {
          const url = yield call(
            rsf.storage.getDownloadURL,
            `quests/${reference.questKey}/${reference.scenarioId}/heritages/${heritage.img}`
          );
          heritagesPictureSourceUrl.push(
            (heritage = {
              ...heritage,
              imgUrl: url,
            })
          );
        }
      })
    );
    yield put(actions.loadHeritagesSuccess(heritagesPictureSourceUrl));
  } catch (error) {
    console.log(error);
    yield put(actions.loadError(error));
  }
}
*/

function* createHeritage(data) {
  const { reference, picture, text } = data.payload;
  console.log(picture);
  const identi = function () {
    return Math.random().toString(36).substr(2, 9);
  };
  let id;
  if (text.id) {
    id = text.id;
  } else {
    id = `insturction_${identi()}_${text.title.fr}`;
  }
  const format = picture.name.slice(-3);
  const url = `https://storage.googleapis.com/granaquestbackend.appspot.com/quests/${reference.questKey}/${reference.scenarioId}/heritages/${id}.${format}`;
  const task = rsf.storage.uploadFile(
    `quests/${reference.questKey}/${reference.scenarioId}/heritages/${id}.${format}`,
    picture
  );
  const newHeritage = {
    ...text,
    imgUrl: url,
    img: `${id}.${format}`,
    id: id,
  };
  try {
    yield task;
    yield put(actions.createHeritageSuccess({ newHeritage }));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

function* updateHeritage(data) {
  console.log(data);
  const { reference, picture, text } = data.payload;
  const newHeritage = {
    ...text,
  };

  try {
    if (picture !== null) {
      const task = rsf.storage.uploadFile(
        `quests/${reference.questKey}/${reference.scenarioId}/heritages/${text.id.img}`,
        picture
      );
      yield task;
    }

    yield put(actions.updateHeritageSuccess(newHeritage));
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

/*function* deleteHeritage(data) {
  const { heritageData } = data.payload;
  try {
    yield storageRef(data, 'heritages', heritageData.id)
      .delete()
      .then(() => console.log('File deleted successfully!'))
      .catch(error => console.log(error));

    yield call(loadHeritages, data);
    yield call(createJson, data);
  } catch (error) {
    yield put(actions.loadError(error));
  }
}
*/

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.LOAD_HERITAGES, loadHeritages),
    takeEvery(actions.CREATE_HERITAGE, createHeritage),
    takeEvery(actions.UPDATE_HERITAGE, updateHeritage),
    // takeEvery(actions.DELETE_HERITAGE, deleteHeritage),
  ]);
}
